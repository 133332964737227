//import { APP_PREFIX_PATH } from "configs/AppConfig";
import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper"
import { ROLE_FOR } from "constants/App/RoleConstant";
import RolesService from "services/Roles/index";
const commonOrderBy = {
    orderBy: "created_at",
    sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} roles`
}

class RolesStore {
    store;
    @observable availableTabs = [];
    @observable activeTab = null;

    @observable tableLoading = false

    @observable masterRoleTableData = []
    @observable masterRoleTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        role_for: ROLE_FOR.SYSTEM_OWNERS,
    };
    @observable hospitalRoleTableData = []
    @observable hospitalRoleTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        role_for: ROLE_FOR.HOSPITAL,
    };
    @observable isRoleModal = false;
    @observable roleRecord = {};
    @observable isBtnLoading = false;
  

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setActiveTab = (tab = "master", push = false) => {
        this.getRolesList(this.masterRoleTablePagination)
    }

    getRolesList = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all,
                role_for: this.store.commonStore.product_name === "master" ? 1 : 2
            }
            const { data } = yield RolesService.rolesList(this.store.commonStore.product_name,dataObjectForServer)
            this.masterRoleTablePagination = update(this.masterRoleTablePagination, {
                total: {
                    $set: data.total
                }
            })
            this.masterRoleTableData = update(this.masterRoleTableData, {
                $set: data.rows
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleAddEditRole = flow(function *(values, form) {
        try {
            this.isBtnLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                role_name: values.role_name,
                role_for: this.store.commonStore.product_name === "master" ? 1 : 2
            }
            if (this.roleRecord) {
                dataObjectForServer.role_id = this.roleRecord.role_id
            }
            yield RolesService.addUpdateRole(this.store.commonStore.product_name,dataObjectForServer)
            this.isBtnLoading = false
            this.isRoleModal = false;
            form && form.resetFields();
            this.getRolesList(this.masterRoleTablePagination)
            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleVisibleAddEditRoleDrawer = (visible, record = {}, form) => {
        console.log("Role Record =============", record);
        this.isRoleModal = visible;
        this.roleRecord = record;
        if(visible === false) {
            form && form.resetFields();
        }
    }

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                this.masterRoleTablePagination = update(this.masterRoleTablePagination, objToUpdate)
                this.getRolesList(this.masterRoleTablePagination)
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                this.masterRoleTablePagination = update(this.masterRoleTablePagination, sortToUpdate)
                this.getRolesList(this.masterRoleTablePagination)
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        this.masterRoleTablePagination = update(this.masterRoleTablePagination, updateObject)
        if (!value) {
            this.onSearch(value)
        }
    }

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        this.masterRoleTablePagination = update(this.masterRoleTablePagination, updateObject)
        this.getRolesList(this.masterRoleTablePagination)
    }

    @action handleAddEditRoleModal = (visible, record = {}) => {
        this.addEditRoleModalVisible = visible
        this.addEditRoleRecord = record
    }

    @action handleSaveRole = flow(function* (values) {
        this.submittingForm = true
        try {
            const dataObj = {
                ...this.store.commonStore.getDefaultParameter(),
                ...values,
                role_for: this.store.commonStore.product_name === "master" ? 1 : 2
            }
            if (this.addEditRoleRecord && this.addEditRoleRecord.role_id) {
                dataObj.role_id = this.addEditRoleRecord.role_id
            }
            yield RolesService.saveRole(this.store.commonStore.product_name,dataObj)
            this.submittingForm = false
            this.handleAddEditRoleModal(false)
        } catch (error) {
            this.submittingForm = false
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleSockets = (socketObject) => {
        const { data, flag } = socketObject
        switch (flag) {
            case "ADD":
                const foundSORole = this.masterRoleTableData.findIndex(r => r.role_id === data.role_id);
                if (foundSORole < 0) {
                    this.masterRoleTableData = update(this.masterRoleTableData, {
                        $push: [data]
                    })
                }
                break;
            case "UPDATE":
                const foundSOUpdateRole = this.masterRoleTableData.findIndex(r => r.role_id === data.role_id);
                if (foundSOUpdateRole > -1) {
                    this.masterRoleTableData = update(this.masterRoleTableData, {
                        [foundSORole]: {
                            $set: data
                        }
                    })
                }    
                break;
            default:
                break;
        }
    }

}

export default RolesStore