import fetch from 'auth/FetchInterceptor'

const EnergyServices = {}
const energyRoute = '/energy/'

EnergyServices.EnergyList = function (params) {
    return fetch({
        url: energyRoute + 'list',
        method: 'post',
        data: params,
    })
}


EnergyServices.saveEnergy = function (params) {
    return fetch({
        url: energyRoute + 'create',
        method: 'post',
        data: params,
    })
}

EnergyServices.deletePlanService = function (params) {
    return fetch({
        url: energyRoute + 'deletePlanService',
        method: 'post',
        data: params,
    })
}


export default EnergyServices