import fetch from 'auth/FetchInterceptor'

const AuthService = {}
const authRoute = '/auth/'

AuthService.login = function (params) {
    return fetch({
        url: authRoute + 'login',
        method: 'post',
        data: params,
        headers: {
            'public-request': 'true'
        }
    })
}

AuthService.generateOTP = function (params) {
    return fetch({
        url: authRoute + "generate_otp",
        method: 'post',
        data: params,
        headers: {
            'public-request': 'true'
        }
    })
}
AuthService.getUserInformationByToken = (data, token) => fetch({
    url: authRoute + "token_info",
    method: "get",
    params: data,
    headers: {
        Authorization: `Bearer ${token}`,
        'public-request': 'true'
    }
})

AuthService.setPassword = (data, token) => fetch({
    url: authRoute + "set_password",
    method: "post",
    data: data,
    headers: {
        Authorization: `Bearer ${token}`,
        'public-request': 'true'
    }
})
   
AuthService.forgetPassword = (data) => fetch({
    url: authRoute + "forget_password",
    method: "post",
    data: data,
    headers: {
        'public-request': 'true'
    }
})

AuthService.loginWithOTP = function (params) {
    return fetch({
        url: "/auth/login_with_otp",
        method: 'post',
        data: params,
        headers: {
            'public-request': 'true'
        }
    })
}

AuthService.masterLogin = function (params) {
    return  fetch({
        url: "/auth/master_login",
        method: 'post',
        data: params,
        headers: {
            'public-request': 'true'
        }
    })
}

AuthService.initApp = ( params) => {
    return  fetch({
        url: "/user/init_call",
        method: 'post',
        data:params
    })
}

AuthService.mastrInit = function (params) {
    return  fetch({
        url: "/master/setting/employee/init",
        method: 'post',
        data:params,
        headers: {
            'master-request': 'true'
        }
    })
}

AuthService.setPost = function* (data) {
    return yield fetch({
        url: '/posts',
        method: 'post',
        data: data
    })
}

AuthService.saveEmployeeProfile = (data) => fetch({
    url: "/organization/setting/employee/save_profile",
    method: 'post',
    data,
})

AuthService.changeAdminPassword = (data) => fetch({
    url: `/organization/setting/employee/change_password`, 
    method: 'post',
    data, 
});
  

export default AuthService