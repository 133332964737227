import { APP_PREFIX_PATH } from "configs/AppConfig";
import moment from "moment";
class Utils {

	/**
	 * Get first character from first & last sentences of a username
	 * @param {String} name - Username
	 * @return {String} 2 characters string
	 */
	static getNameInitial(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
	}

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo(navTree, path) {
		if (navTree.path && path.indexOf(navTree.path) > -1) {
			return navTree;
		}
		let route;
		for (let p in navTree) {
			if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object' && navTree[p].key) {
				route = this.getRouteInfo(navTree[p], path);
				if (route) {
					return route;
				}
			}
		}
		return route;
	}

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex) {
		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);
		function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
		function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
		function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
		function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h }
		const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
		if (cBrightness > threshold) {
			return 'dark'
		} else {
			return 'light'
		}
	}

	/**
	 * Darken or lighten a hex color 
	 * @param {String} color - Hex color code e.g '#3e82f7'
	 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
	 * @return {String} Darken or lighten color 
	 */
	static shadeColor(color, percent) {
		let R = parseInt(color.substring(1, 3), 16);
		let G = parseInt(color.substring(3, 5), 16);
		let B = parseInt(color.substring(5, 7), 16);
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
		R = (R < 255) ? R : 255;
		G = (G < 255) ? G : 255;
		B = (B < 255) ? B : 255;
		const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
		const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
		const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
		return `#${RR}${GG}${BB}`;
	}

	/**
	 * Returns either a positive or negative 
	 * @param {Number} number - number value
	 * @param {any} positive - value that return when positive
	 * @param {any} negative - value that return when negative
	 * @return {any} positive or negative value based on param
	 */
	static getSignNum(number, positive, negative) {
		if (number > 0) {
			return positive
		}
		if (number < 0) {
			return negative
		}
		return null
	}

	/**
	 * Returns either ascending or descending value
	 * @param {Object} a - antd Table sorter param a
	 * @param {Object} b - antd Table sorter param b
	 * @param {String} key - object key for compare
	 * @return {any} a value minus b value
	 */
	static antdTableSorter(a, b, key) {
		if (typeof a[key] === 'number' && typeof b[key] === 'number') {
			return a[key] - b[key]
		}

		if (typeof a[key] === 'string' && typeof b[key] === 'string') {
			a = a[key].toLowerCase();
			b = b[key].toLowerCase();
			return a > b ? -1 : b > a ? 1 : 0;
		}
		return
	}

	/**
	 * Filter array of object 
	 * @param {Array} list - array of objects that need to filter
	 * @param {String} key - object key target
	 * @param {any} value  - value that excluded from filter
	 * @return {Array} a value minus b value
	 */
	static filterArray(list, key, value) {
		let data = list
		if (list) {
			data = list.filter(item => item[key] === value)
		}
		return data
	}

	/**
	 * Remove object from array by value
	 * @param {Array} list - array of objects
	 * @param {String} key - object key target
	 * @param {any} value  - target value
	 * @return {Array} Array that removed target object
	 */
	static deleteArrayRow(list, key, value) {
		let data = list
		if (list) {
			data = list.filter(item => item[key] !== value)
		}
		return data
	}

	/**
	 * Wild card search on all property of the object
	 * @param {Number | String} input - any value to search
	 * @param {Array} list - array for search
	 * @return {Array} array of object contained keyword
	 */
	static wildCardSearch(list, input) {
		const searchText = (item) => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}
				if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
					return true;
				}
			}
		};
		list = list.filter(value => searchText(value));
		return list;
	}

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens) {
		let breakpoints = []
		for (const key in screens) {
			if (screens.hasOwnProperty(key)) {
				const element = screens[key];
				if (element) {
					breakpoints.push(key)
				}
			}
		}
		return breakpoints
	}

	static DEFAULT_PAGINATION = {
		total: 0,
		pageSize: 20,
		defaultCurrent: 1,
		current: 1,
		showSizeChanger: true,
		search: "",
	};

	static SET_PASSWORD_LINK = window.location.origin + "/" + APP_PREFIX_PATH + "/set-password";

	static availableViews = {
		VIEW_LIST: 'LIST',
		VIEW_GRID: 'GRID',
	}

	static isMobile = () => window.innerWidth < 768;

	static WeightUnits = [
		{
			label: "MG",
			value: 1
		},
		{
			label: "G",
			value: 2
		},
		{
			label: "KG",
			value: 3
		},
		{
			label: "ML",
			value: 4
		},
		{
			label: "LTR",
			value: 5
		},

	]

	static Saluations = [
		{
			label: "Mr.",
			value: 1
		},
		{
			label: "Mrs.",
			value: 2
		},
		{
			label: "Ms.",
			value: 3
		},
		{
			label: "Miss.",
			value: 4
		},
		{
			label: "Dr.",
			value: 5
		},

	]

	static ItemUnits = [
		{
			label: "Box",
			value: 1
		},
		{
			label: "CM",
			value: 2
		},
		{
			label: "DZ",
			value: 3
		},
		{
			label: "FT",
			value: 4
		},
		{
			label: "G",
			value: 5
		},
		{
			label: "IN",
			value: 6
		},
		{
			label: "KG",
			value: 7
		},
		{
			label: "KM",
			value: 8
		},
		{
			label: "LB",
			value: 9
		},
		{
			label: "MG",
			value: 10
		},
		{
			label: "M",
			value: 11
		},
		{
			label: "PCS",
			value: 12
		},
		{
			label: "LTR",
			value: 13
		},
		{
			label: "ML",
			value: 14
		},
	]

	static delieveryMethodTypes = () => ({
		1: "Self delievery",
		2: "Courier",
		3: "Third party delievery"
	})

	static dateFormatter = (DDMMYYYY, format = "DD-MM-YYYY hh:mm A", tz = "Asia/Kolkata") => {
		return moment(new Date(DDMMYYYY)).format(format);
	};

	static findCallNumberonMessage = (text) => {
		var urrled = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im;
		var rx = /^[+-]?\d+$/;
		let regexTest = text.split(" ").filter((eve) => rx.test(eve) === true);
		text =
			regexTest.length && regexTest.length > 0
				? regexTest.length > 1
					? regexTest[0] + regexTest[1]
					: regexTest[0]
				: text;
		if (urrled.test(text)) {
			return text;
		} else {
			return undefined;
		}
	}

	static findUrlOnMessage = (text) => {
		var urlRegex = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gi;
		text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
		text = text.replace(/\r?\n/g, " <br/>");
		return text.replace(urlRegex, function (url) {
			let urlLink = url.includes("http://") || url.includes("https://") ? url : "https://" + url;
			return '<a   target="_blank" rel="noopener noreferrer"  href="' + urlLink + '">' + url + "</a>";
		});
	};

	static calendarRanges = (props) => {
		const {
			intl: { formatMessage },
		} = props;
		const range = {};
		range[formatMessage({ id: "date.today" })] = [moment().startOf("day"), moment().endOf("day")];
		range[formatMessage({ id: "date.yesterday" })] = [
			moment().subtract(1, "days").startOf("day"),
			moment().subtract(1, "days").endOf("day"),
		];
		range[formatMessage({ id: "date.last_7_days" })] = [
			moment().subtract(7, "days").startOf("day"),
			moment().subtract(1, "days").endOf("day"),
		];
		range[formatMessage({ id: "date.last_30_days" })] = [
			moment().subtract(30, "days").startOf("day"),
			moment().subtract(1, "days").endOf("day"),
		];
		range[formatMessage({ id: "date.current_month" })] = [
			moment().startOf("month"),
			moment().subtract(1, "days").endOf("day"),
		];
		range[formatMessage({ id: "date.last_month" })] = [
			moment().subtract(1, "month").startOf("month"),
			moment().subtract(1, "month").endOf("month"),
		];
		range[formatMessage({ id: "date.last_3_months" })] = [
			moment().subtract(3, "month").startOf("month"),
			moment().subtract(1, "month").endOf("month"),
		];
		return range;
	};

	static convertPaginationparams = (pagination = {}) => {
		const newPagination = {...pagination}
		newPagination.page = newPagination.current
		newPagination.sort_field = newPagination.orderBy
		newPagination.sort_by = newPagination.sortBy
		newPagination.page_limit = newPagination.pageSize
		delete newPagination.current
		delete newPagination.orderBy
		delete newPagination.sortBy
		delete newPagination.pageSize
		return newPagination
	}

}

export default Utils;