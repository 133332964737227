export const AUTH_TOKEN = "authtoken";
export const ADMIN_USER_ID = "userId";
export const PRODUCT_OBJECT = "product_in";

export default class AppConstantClass {
  static getAdminAuthToken() {
    return localStorage.getItem(AUTH_TOKEN);
  }

  static setAdminAuthToken(token) {
    return localStorage.setItem(AUTH_TOKEN, token);
  }

  static getAdminUserId() {
    return localStorage.getItem(ADMIN_USER_ID);
  }

  static setAdminUserId(userid) {
    localStorage.setItem(ADMIN_USER_ID, userid);
  }

  static setProductId(productObject) {
    localStorage.setItem(PRODUCT_OBJECT, JSON.stringify(productObject));
  }

  static getProductInfo() {
    const product = localStorage.getItem(PRODUCT_OBJECT);
    if (product) {
      try {
        return JSON.parse(product);
      } catch (error) {
        return null;
      }
    }
    return null;
  }
}
