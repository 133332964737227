const dev = {
  API_ENDPOINT_URL: 'http://localhost:5000',
  API_SOCKET_URL: "http://localhost:5000",
  EPROC_FRONTEND: "http://localhost:3001",
  ENERGY_FRONTEND: "http://localhost:3001",
  SET_PASSWORD: "/set-password",
};

const prod = {
  API_ENDPOINT_URL: 'https://master-api.codeminit.com',
  API_SOCKET_URL: "https://master-api.codeminit.com",
  EPROC_FRONTEND: "http://localhost:3001",
  ENERGY_FRONTEND: "http://localhost:3001",
  SET_PASSWORD: "/set-password",
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com',
  API_SOCKET_URL: "http://localhost",
  EPROC_FRONTEND: "http://localhost:3001",
  ENERGY_FRONTEND: "http://localhost:3001",
  SET_PASSWORD: "/set-password",
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
