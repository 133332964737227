

import fetch from 'auth/FetchInterceptor'

const ProductService = {}
const productRoute = '/products/'

ProductService.productsList = function (params) {
    return fetch({
        url: productRoute + 'list',
        method: 'post',
        data: params,
    })
}

ProductService.addUpdateProduct = function (params) {
    return fetch({
        url: productRoute + 'create',
        method: 'post',
        data: params,
    })
}

ProductService.deleteProduct = function (params) {
    return fetch({
        url: productRoute + 'delete',
        method: 'delete',
        data: params,
    })
}

export default ProductService