import antdInHi from 'antd/es/locale/hi_IN';
import hiMsg from "../locales/in_HI.json";

const ZhLang = {
  antd: antdInHi,
  locale: 'in-HI',
  messages: {
    ...hiMsg
  },
};
export default ZhLang;
