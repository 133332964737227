import antdInGu from './../locales/antd/gu';
import guMsg from "../locales/in_GU.json";

const GULang = {
  antd: antdInGu,
  locale: 'in-GU',
  messages: {
    ...guMsg
  },
};
export default GULang;
