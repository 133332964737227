import React from 'react'
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import utils from 'utils';
import { Grid } from 'antd';
import { inject, observer } from "mobx-react"

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props.themeStore;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (props) => {
  const {
    themeStore: { navCollapsed },
  } = props;

  if (navCollapsed) {
    return require("assets/images/favicon.ico");
  }
  return require("assets/images/logo_2x.png");
};

const getLogoWidth = (props) => {
  const {
    themeStore: { navCollapsed },
  } = props;

  if (navCollapsed) {
    return "";
  }
  return "90%";
}

const getLogoHeight = (props) => {
  const {
    themeStore: { navCollapsed },
  } = props;

  if (navCollapsed) {
    return "50px";
  }
  return "55px";
};


const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}>
      <img src={getLogo(props)} alt={`${APP_NAME} logo`} style={{
          height: getLogoHeight(props),
          width: getLogoWidth(props)
        }} />
    </div>
  )
}
export default inject("themeStore", "commonStore")(observer(Logo))