import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { notification } from 'antd';
import { AUTH_TOKEN } from 'configs/Constants';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer ' + jwtToken
	}

	if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}
	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

const downloadFile = (data, headers) => {
	let disposition = headers["content-disposition"];
	let blob = new Blob([data]),
		downloadUrl = window.URL.createObjectURL(blob),
		filename = "test.pdf";
	if (disposition && disposition.indexOf("attachment") !== -1) {
		let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
			matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, "");
		}
	}

	let a = document.createElement("a");
	if (typeof a.download === "undefined") {
		window.location.href = downloadUrl;
	} else {
		a.href = downloadUrl;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(downloadUrl);
	}
}

// API respone interceptor
service.interceptors.response.use((response) => {
	if (response.headers && response.headers["content-disposition"]) {
		downloadFile(response.data, response.headers);
		return false;
	}
	return response.data
}, (error) => {

	let notificationParam = {
		message: error.response && error.response.data.message.en
	}
	if (error.response && (error.response.status === 400)) {
		return Promise.reject(error.response);
	}

	// Remove token and redirect 
	if (error.response && (error.response.status === 401 || error.response.status === 403)) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.clear()
		history.push(ENTRY_ROUTE)
		notification.error(notificationParam)
	}

	return Promise.reject(error.response || error);
});

export default service