import React, {
    //lazy,
    // lazy, 
    Suspense
} from "react";
import Loadable from "react-loadable";
import { Switch, Redirect } from "react-router-dom";
import Loading from 'shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { observer, inject } from "mobx-react";
import Permission from "shared-components/hoc/Permission";

const CareerCategory = Loadable({
    loader: () => import('./CareerCategoryManagement'),
    loading: () => <Loading cover="page" />,
});

const Energy = Loadable({
    loader: () => import('./Energy'),
    loading: () => <Loading cover="page" />,
});

const Dairy =  Loadable({
    loader: () => import('./DairyProcurement'),
    loading: () => <Loading cover="page" />,
});

const Roles = Loadable({
    loader: () => import('./Roles'),
    loading: () => <Loading cover="page" />,
});

const Users = Loadable({
    loader: () => import('./Users'),
    loading: () => <Loading cover="page" />,
});

const Services = Loadable({
    loader: () => import('./Services'),
    loading: () => <Loading cover="page" />,
});

const Plans = Loadable({
    loader: () => import('./Plans'),
    loading: () => <Loading cover="page" />,
});

const AccessControl = Loadable({
    loader: () => import('./AccessControl'),
    loading: () => <Loading cover="page" />,
});

const ProductManagement = Loadable({
    loader: () => import('./ProductManagement'),
    loading: () => <Loading cover="page" />,
});

const CareerList = Loadable({
    loader: () => import('./CareerListManagement'),
    loading: () => <Loading cover="page" />,
});

const BlogList = Loadable({
    loader: () => import('./BlogManagement'),
    loading: () => <Loading cover="page" />,
});

const AppViews = (props) => {
    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/dairy`} component={Dairy} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/energy`} component={Energy} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/roles`} component={Roles} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/users`} component={Users} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/services`} component={Services} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/plans`} component={Plans} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/access-controls`} component={AccessControl} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/products`} component={ProductManagement} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/career-category`} component={CareerCategory} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/career-list`} component={CareerList} />
                <Permission ids={[1910]} path={`${APP_PREFIX_PATH}/blog-management`} component={BlogList} />
                {
                    props.themeStore.selectedRoute && (
                        <Redirect from={`${APP_PREFIX_PATH}`} to={`${props.themeStore.selectedRoute}`} />
                    )
                }
            </Switch>
        </Suspense>
    )
}
export default inject("themeStore")(observer(AppViews));