import antdInMa from './../locales/antd/ma';
import maMsg from "../locales/in_MA.json";

const ZhLang = {
  antd: antdInMa,
  locale: 'in-MA',
  messages: {
    ...maMsg
  },
};
export default ZhLang;
