import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper";
import { ROLE_FOR } from "constants/App/RoleConstant";
import BlogService from "services/BlogManagement/index";
import axios from "../../../../node_modules/axios/index";

const commonOrderBy = {
  orderBy: "created_at",
  sortBy: "DESC",
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`,
};

class BlogStore {
  store;
  @observable tableLoading = false;

  @observable hospitalPlansTableData = [];
  @observable hospitalPlansTablePagination = {
    ...Utils.DEFAULT_PAGINATION,
    ...commonOrderBy,
    ds_for: ROLE_FOR.HOSPITAL,
  };
  @observable isServiceModal = false;
  @observable serviceRecord = {};
  @observable isBtnLoading = false;
  @observable ds_price_base = false;
  @observable ds_service = false;
  @observable services = [];
  @observable uploadFileList = [];
  @observable previewImage = "";
  @observable previewVisible = false;
  @observable imageRecord = {};
  @observable uploadBlogFileList = [];
  @observable bgm_description = "";

  constructor(stores) {
    this.store = stores;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setValue = (key, value) => {
    this[key] = value;
  };

  getBlogLists = flow(function* (
    pagination = Utils.DEFAULT_PAGINATION,
    all = false,
    loader = true
  ) {
    try {
      this.tableLoading = loader;
      const dataObjectForServer = {
        ...this.store.commonStore.getDefaultParameter(),
        ...Utils.convertPaginationparams(pagination),
        all: all,
      };
      const { data } = yield BlogService.getBlogManagementList(
        dataObjectForServer
      );

      this.hospitalPlansTablePagination = update(
        this.hospitalPlansTablePagination,
        {
          total: {
            $set: data.total,
          },
        }
      );
      this.hospitalPlansTableData = update(this.hospitalPlansTableData, {
        $set: data.rows,
      });
      this.tableLoading = false;
    } catch (error) {
      this.tableLoading = false;
      this.store.commonStore.responseMessageHandler(error);
    }
  });

  @action handleRemoveBlog = flow(function* (rowRecord) {
    try {
      this.tableLoading = true;
      const dataObjectForServer = {
        ...this.store.commonStore.getDefaultParameter(),
        bgm_id: rowRecord.bgm_id,
      };
      const { data } = yield BlogService.deleteBlog(
        dataObjectForServer
      );
      this.store.commonStore.responseMessageHandler(data);
      this.tableLoading = false;
      this.getBlogLists(this.hospitalPlansTablePagination);
    } catch (error) {
      this.isBtnLoading = false;
      this.store.commonStore.responseMessageHandler(error);
    }
  });

  handleRemoveFile = () => {
    this.uploadFileList = update(this.uploadFileList, {
      $set: [],
    });
  };

  handleCancel = (visible) => {
    this.previewImage = "";
    this.previewVisible = visible;
    this.imageRecord = {};
  };

  handleBlogRemoveFile = flow(function* (fileList) {
    if (fileList && fileList.bli_id) {
      try {
        const dataObjectForServer = {
          ...this.store.commonStore.getDefaultParameter(),
          bli_id: fileList.bli_id,
          bgm_id: fileList.bgm_id,
          so_id: 1,
        };
        yield BlogService.deleteBlogImage(dataObjectForServer);
        this.getBlogLists(this.hospitalPlansTablePagination);
      } catch (error) {
        this.tableLoading = false;
        this.store.commonStore.responseMessageHandler(error);
      }
    } else {
      this.uploadBlogFileList = [];
    }
  });

  @action handleChangeFile = (fileList) => {
    console.log("fileList", fileList);
    if (fileList.fileList && fileList.fileList.length) {
      fileList.fileList[0].status = "done";
    }
    this.uploadBlogFileList = update(this.uploadBlogFileList, {
      $set: fileList.fileList,
    });
  };

  getBase64 = (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = flow(function* (file) {
    if (!file.url && !file.preview) {
      file.preview = yield this.getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
    this.imageRecord = file;
  });

  @action handleAddEditBlog = flow(function* (values, form) {
    try {
      this.isBtnLoading = true;
      const dataObjectForServer = {
        ...this.store.commonStore.getDefaultParameter(),
        bgm_title: values.bgm_title,
        bgm_description: this.bgm_description,
        bgm_meta_title: values.bgm_meta_title,
        bgm_meta_keywords: values.bgm_meta_keywords,
        bgm_meta_description: values.bgm_meta_description,
        bgm_active: values.bgm_active, 
        bgm_is_featured: values.bgm_is_featured 
      };

      if (Object.keys(this.serviceRecord).length > 0) {
        dataObjectForServer.bgm_id = this.serviceRecord.bgm_id;
      }

      const data = new FormData();
      if (
        Object.keys(dataObjectForServer) &&
        Object.keys(dataObjectForServer).length
      ) {
        Object.keys(dataObjectForServer).map((it) => {
          data.append(it, dataObjectForServer[it]);
          return it;
        });
      }
      if (this.uploadBlogFileList && this.uploadBlogFileList.length) {
        console.log("In If Condition ======");
        this.uploadBlogFileList.map((it) => {
          console.log("it image ==========", it);
          if (it._id === undefined) {
            data.append("image", it.originFileObj);
          }
          return it;
        });
      }

      console.log("data ==============", data);

      const res = yield BlogService.addUpdateBlog(
        data
      );
      this.isBtnLoading = false;
      this.isServiceModal = false;
      form && form.resetFields();
      this.serviceRecord = {};
      this.uploadBlogFileList = [];
      this.uploadFileList = [];
      this.imageRecord = {};
      this.store.commonStore.responseMessageHandler(res);
      this.getBlogLists(this.hospitalPlansTablePagination);
    } catch (error) {
      this.isBtnLoading = false;
      this.store.commonStore.responseMessageHandler(error);
    }
  });

  @action handleVisibleAddEditBlogModal = async(visible, record = {}, form) => {
    console.log("record ======================", record);
    this.isServiceModal = visible;
    if (!!Object.keys(record).length) {
      this.serviceRecord = update(this.serviceRecord, {
        $set: record,
      });

      const getData = (url) => new Promise(async (resolve) => { 
        axios.get(url).then(res => res.data).then( res => resolve(res))
      })
      if (record && record.blog_images && record.blog_images.length) {
        let attachments = [];
        await Promise.all(record.blog_images.map(async (item) => {
          let temp = {
            ...item,
            url: item.bli_url,
            uid: item.bli_id,
            type: item.bli_mimetype
          };
          if (item.bli_url.includes(".svg")) {
            const res = await getData(item.bli_url)
            console.log(res)
            let source = res
            if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
              source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
            }
            if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
                source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
            }
            source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
            var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
            temp.url = url 
            temp = {
              ...temp,
            }
          }
          attachments.push(temp);
          return item;
        }));
        this.uploadBlogFileList = update(this.uploadBlogFileList, {
          $set: attachments,
        });
      }
    } else {
      this.serviceRecord = update(this.serviceRecord, {
        $set: {},
      });
      this.imageRecord = update(this.imageRecord, {
        $set: {},
      })
    }
    if (visible === false) {
      this.uploadBlogFileList = [];
      this.uploadFileList = [];
      form && form.resetFields();
    }
  };

  @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
    switch (action) {
      case "paginate":
        const objToUpdate = {
          current: {
            $set: pagination.current,
          },
          pageSize: {
            $set: pagination.pageSize,
          },
        };
        this.hospitalPlansTablePagination = update(
          this.hospitalPlansTablePagination,
          objToUpdate
        );
        this.getBlogLists(this.hospitalPlansTablePagination);
        break;
      case "sort":
        const sortToUpdate = {
          orderBy: {
            $set: sorter.field || "created_at",
          },
          sortBy: {
            $set: sorter.order === "ascend" ? "ASC" : "DESC",
          },
        };
        this.hospitalPlansTablePagination = update(
          this.hospitalPlansTablePagination,
          sortToUpdate
        );
        this.getBlogLists(this.hospitalPlansTablePagination);
        break;
      default:
        break;
    }
  };

  @action onSearchChange = ({ target: { value } }) => {
    const updateObject = {
      search: {
        $set: value,
      },
    };
    this.hospitalPlansTablePagination = update(
      this.hospitalPlansTablePagination,
      updateObject
    );
    if (!value) {
      this.onSearch(value);
    }
  };

  @action onSearch = (value) => {
    const updateObject = {
      current: {
        $set: 1,
      },
      search: {
        $set: value,
      },
    };
    this.hospitalPlansTablePagination = update(
      this.hospitalPlansTablePagination,
      updateObject
    );
    this.getBlogLists(this.hospitalPlansTablePagination);
  };
}
export default BlogStore;
