import { APP_PREFIX_PATH } from "configs/AppConfig";
import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper";
import CareerCategoryService from "services/CareerCategory/index";
import CareerListService from "services/CareerList/index";

const commonOrderBy = {
    orderBy: "created_at",
    sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`
}

class CareerStore {
    store;
    @observable availableTabs = [
        {
            key: "active",
            tab: `Active`,
        },
        {
            key: "expired",
            tab: `Expired`,
        },
    ];
    @observable activeTab = "active";
    @observable tableLoading = false;

    @observable activeTableData = []
    @observable activeTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        ds_for: 1,
    };
    @observable deactiveTableData = []
    @observable deactiveTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        ds_for: 1,
    };
    @observable isServiceModal = false;
    @observable serviceRecord = {};
    @observable isBtnLoading = false;
    @observable ds_price_base = false;
    @observable ds_service = false;
    @observable services = [];
    @observable allParentCareerCategoryLists = [];
    @observable subCategoryLists = [];
    @observable addEditCareerModallVisible = false;
    @observable career_opening_description = "";

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setValue = (key, value) => {
        this[key] = value;
    }

    getParentSubCategoryLists = flow(function* (category_id) {
        try {
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                career_category_id: category_id
            }
            const { data } = yield CareerCategoryService.SubCategoryLists(dataObjectForServer)
            
            this.subCategoryLists = update(this.subCategoryLists, {
                $set: data.rows
            })
        } catch (error) {
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    getAllProductLists = flow(function* () {
        try {
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
            }
            const { data } = yield CareerCategoryService.ParentCategoryLists(dataObjectForServer)
            
            this.allParentCareerCategoryLists = update(this.allParentCareerCategoryLists, {
                $set: data.rows
            })
        } catch (error) {
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    setActiveTab = (tab = "active", push = false) => {
        this.activeTab = tab;
        if (push) {
            this.store.router.history.push(`${APP_PREFIX_PATH}/career-list/${tab}`)
        }
        switch (tab) {
            case "expired":
                this.getCareerList(tab, this.deactiveTablePagination)
                break;
            default:
                this.getCareerList(tab, this.activeTablePagination)
                break;
        }
    }

    getCareerList = flow(function* (tab, pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all:all,
                tab:tab
            }
            const { data } = yield CareerListService.getCareerList(dataObjectForServer)
            console.log("career list data -----------", data);
            switch (tab) {
                case "expired":
                    this.deactiveTablePagination = update(this.deactiveTablePagination, {
                        total: {
                            $set: data.total
                        }
                    })
                    this.deactiveTableData = update(this.deactiveTableData, {
                        $set: data.rows
                    })
                    break;
                default:
                    this.activeTablePagination = update(this.activeTablePagination, {
                        total: {
                            $set: data.total
                        }
                    })
                    this.activeTableData = update(this.activeTableData, {
                        $set: data.rows
                    })
                    break;
            }
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleRemoveCareerList = flow(function *(rowRecord){
        try {
            this.tableLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                career_category_list_id: rowRecord.career_category_list_id
            }
            const { data } = yield CareerListService.deleteCareerList(dataObjectForServer)
            this.store.commonStore.responseMessageHandler(data)
            this.tableLoading = false;
            switch (this.activeTab) {
                case "deactive":
                    this.getCareerList(this.deactiveTablePagination);
                    break;
                default:
                    this.getCareerList(this.activeTablePagination);
                    break;
            }
            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleAddEditCareerList = flow(function *(values, form) {
        try {
            this.isBtnLoading = true;
            console.log("this.career_opening_description =======", this.career_opening_description);
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                career_category_id: values.career_category_id,
                career_opening_start_date: values.career_opening_start_date,
                career_opening_end_date: values.career_opening_end_date,
                career_opening_experience: values.career_opening_experience,
                career_opening_description: this.career_opening_description,
                career_opening_active_status: values.career_opening_active_status
            }
            if (this.serviceRecord) {
                dataObjectForServer.career_category_list_id = this.serviceRecord.career_category_list_id
            }
            const res  = yield CareerListService.addUpdateCareerList(dataObjectForServer)
            this.isBtnLoading = false
            this.addEditCareerModallVisible = false;
            form && form.resetFields();
            this.serviceRecord = {}
            this.store.commonStore.responseMessageHandler(res)
            switch (this.activeTab) {
                case "expired":
                    this.getCareerList(this.deactiveTablePagination);
                    break;
                default:
                    this.getCareerList(this.activeTablePagination);
                    break;
            }
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleVisibleAddEditCareerDrawer = (visible, record = {}, form) => {
        console.log("record ======================", record);
        this.addEditCareerModallVisible = visible;
        
        if(!!Object.keys(record).length) {
            this.career_opening_description = update(this.career_opening_description,{
                $set: record.career_opening_description
            });
            this.serviceRecord =  update(this.serviceRecord,{
                $set: record
            });
        } else {
            this.career_opening_description = update(this.career_opening_description,{
                $set: ""
            });
            this.serviceRecord = update(this.serviceRecord,{
                $set: {}
            });
        }
        if(visible === false) {
            form && form.resetFields();
        }
    }

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                this.activeTablePagination = update(this.activeTablePagination, objToUpdate)
                this.getCareerList(this.activeTablePagination)
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                this.activeTablePagination = update(this.activeTablePagination, sortToUpdate)
                this.getCareerList(this.activeTablePagination)
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        switch (this.activeTab) {
            case "expired":
                this.deactiveTablePagination = update(this.deactiveTablePagination, updateObject)
                if (!value) {
                    this.onSearch(value)
                }
                break;
            default:
                this.activeTablePagination = update(this.activeTablePagination, updateObject)
                if (!value) {
                    this.onSearch(value)
                }
                break;
        }
        
    }

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        switch (this.activeTab) {
            case "expired":
                this.deactiveTablePagination = update(this.deactiveTablePagination, updateObject)
                this.getCareerList(this.activeTab, this.deactiveTablePagination)
                break;
            default:
                this.activeTablePagination = update(this.activeTablePagination, updateObject)
                this.getCareerList(this.activeTab, this.activeTablePagination)
                break;
        }
        
    }

}

export default CareerStore