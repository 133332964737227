import React from 'react'
import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper"
import { ROLE_FOR } from "constants/App/RoleConstant";
import AccessService from "services/AccessServices/index";
import MasterService from "services/MasterServices/index";
import { ExpandableColumns } from "./Table/Columns/expandColumn";
import TableContainer from 'shared-components/TableContainer';
import ProductService from "services/Products/index";

const commonOrderBy = {
    orderBy: "created_at",
    sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} services`
}

class ServiceStore {
    store;
    @observable availableTabs = [
        {
            key: "master",
            tab: `Master`,
        },
        {
            key: "hospital",
            tab: `Hospital`,
        },
    ];
    @observable activeTab = null;

    @observable tableLoading = false

    @observable masterRoleTableData = []
    @observable masterRoleTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        ds_for: ROLE_FOR.SYSTEM_OWNERS,
    };
    @observable hospitalRoleTableData = []
    @observable hospitalRoleTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        ds_for: ROLE_FOR.HOSPITAL,
    };
    @observable isServiceModal = false;
    @observable serviceRecord = {};
    @observable isBtnLoading = false;
    @observable masterRoleParentTableData = []
    @observable levelLists = []
    @observable disabledAddAccessControl = false;
    @observable allProductLists = []

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setValue = (key, value) => {
        this[key] = value;
    }

    setActiveTab = (tab = "master", push = false) => {
        this.activeTab = tab;
        this.getAccessControlsList(this.masterRoleTablePagination)
    }

    handleChangeAccessControlData = (index, key, value) => {
        this.levelLists = update(this.levelLists, {
          [index]: {
            [key]: { $set: value },
          },
        });
    };

    handleRemoveAccessControls = async (index) => {
        let levelObject = this.levelLists[index];        
        if (levelObject && levelObject.acc_control_id) {
            this.isBtnLoading = true;
            console.log("delete access control res");
            const dataObject = {
                ...this.store.commonStore.getDefaultParameter(),
                acc_control_id: levelObject.acc_control_id
            };
            const res = await AccessService.deleteAccessControl(this.store.commonStore.product_name, dataObject);
            console.log("delete access control res", res);
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(res)
            this.levelLists = update(this.levelLists, {
                $splice: [[index, 1]],
            });
            this.getAccessControlsList(this.masterRoleTablePagination)
        } else {
          this.levelLists = update(this.levelLists, {
            $splice: [[index, 1]],
          });
        }
        this.disabledAddAccessControl = false;
    };

    handleAddAccessControl = () => {
        this.levelLists = update(this.levelLists, {
          $push: [
            {
                acc_control_name: undefined,
                acc_control_code: undefined,
                isEdit: true,
                id: Date.now() + Math.random()
            },
          ],
        });
        this.disabledAddAccessControl = true;
    };

    @action getMasterParentServiceLists = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all,
                ds_for: this.store.commonStore.product_name === "master" ? 1 : 2
            }
            const { data } = yield MasterService.servicesList(this.store.commonStore.product_name, dataObjectForServer)
            let finalRecords = data.rows && data.rows.length > 0 && data.rows.filter((item) => item.ds_parent_id === null)
            console.log("finalRecords ==========", finalRecords);
            this.masterRoleParentTableData = update(this.masterRoleParentTableData, {
                $set: finalRecords
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    customExpandableRecords = (groupLists = [], props) => {
        return (
          <TableContainer
            tableProps={{
              columns: ExpandableColumns(props),
              dataSource: groupLists && groupLists.length > 0 ? groupLists : [],
              rowKey: (record, index) => record.acc_group_id,
              pagination: false
            }}
          />
        );
    };

    getAllProductLists = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all
            }
            const { data } = yield ProductService.productsList(dataObjectForServer)
            
            this.allProductLists = update(this.allProductLists, {
                $set: data.rows
            })
        } catch (error) {
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    getAccessControlsList = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all,
                group_for: this.store.commonStore.product_name === "master" ? 1 : 2
            }
            const { data } = yield AccessService.accessControlLists(this.store.commonStore.product_name, dataObjectForServer)
            console.log("accessControlLists =========", data);
            this.masterRoleTablePagination = update(this.masterRoleTablePagination, {
                total: {
                    $set: data.total
                }
            })
            this.masterRoleTableData = update(this.masterRoleTableData, {
                $set: data.rows
            })
            this.getMasterParentServiceLists();
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleRemoveUser = flow(function *(rowRecord){
        try {
            this.tableLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                acc_group_id: rowRecord.acc_group_id
            }
            const { data } = yield AccessService.deleteAccessControlGroup(this.store.commonStore.product_name, dataObjectForServer)
            this.store.commonStore.responseMessageHandler(data)
            this.tableLoading = false;
            this.getAccessControlsList(this.masterRoleTablePagination)            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleAddEditAccessControl = flow(function *(values, form) {
        try {
            console.log("Values =============", values, this.levelLists);
            this.isBtnLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                so_id: 1,
                acc_group_depend_id: values.acc_group_depend_id,
                acc_group_name: values.acc_group_name,
                acc_controls: this.levelLists,
                acc_product_id: this.store.commonStore.product_id,
                ds_for: this.store.commonStore.product_name === "master" ? 1 : 2
            }
            if (this.serviceRecord) {
                dataObjectForServer.acc_group_id = this.serviceRecord.acc_group_id
            }
            const res  = yield AccessService.addUpdateAccessControl(this.store.commonStore.product_name, dataObjectForServer)
            this.isBtnLoading = false
            this.isServiceModal = false;
            form && form.resetFields();
            this.serviceRecord = {};
            this.levelLists = [];
            this.disabledAddAccessControl = false;
            this.store.commonStore.responseMessageHandler(res)
            this.getAccessControlsList(this.masterRoleTablePagination)
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleVisibleAddEditUserDrawer = (visible, record = {}, form) => {
        this.isServiceModal = visible;
        console.log("record  ===============", record);
        this.serviceRecord = update(this.serviceRecord, {
            $set: record
        });
        if(visible === false) {
            this.levelLists = [];
            this.disabledAddAccessControl = false;
            form && form.resetFields();
        } else {
            record && record.access_controls && record.access_controls.map((item, index) => {
                let temp = {
                    ...item,  
                    isEdit: false,
                    id: item.acc_control_id
                }
                this.levelLists = update(this.levelLists, {
                  $push: [temp]
                });
                return item
            })
        }
    }

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                this.masterRoleTablePagination = update(this.masterRoleTablePagination, objToUpdate)
                this.getAccessControlsList(this.masterRoleTablePagination)
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                this.masterRoleTablePagination = update(this.masterRoleTablePagination, sortToUpdate)
                this.getAccessControlsList(this.masterRoleTablePagination)
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        this.masterRoleTablePagination = update(this.masterRoleTablePagination, updateObject)
        if (!value) {
            this.onSearch(value)
        }
    }

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        this.masterRoleTablePagination = update(this.masterRoleTablePagination, updateObject)
        this.getAccessControlsList(this.masterRoleTablePagination)
    }

}

export default ServiceStore