/* eslint-disable no-template-curly-in-string */
var typeTemplate = '${label} માન્ય ${type} નથી';
const commonDate = {
    locale: 'in-GU',
    today: 'આજે',
    now: 'હવે',
    backToToday: 'આજ સુધી',
    ok: 'બરાબર',
    clear: 'ચોખ્ખુ',
    month: 'માસ',
    year: 'વર્ષ',
    timeSelect: 'સમય પસંદ કરો',
    dateSelect: 'તારીખ પસંદ કરો',
    weekSelect: 'એક અઠવાડિયા પસંદ કરો',
    monthSelect: 'એક મહિના પસંદ કરો',
    yearSelect: 'એક વર્ષ પસંદ કરો',
    decadeSelect: 'એક દાયકા પસંદ કરો',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'પાછલો મહિનો (પૃષ્ઠ ઉપર)',
    nextMonth: 'આવતા મહિને (નીચે પાનું)',
    previousYear: 'છેલ્લું વર્ષ (Ctrl + ડાબે)',
    nextYear: 'આગલું વર્ષ (Ctrl + અધિકાર)',
    previousDecade: 'છેલ્લા દાયકા',
    nextDecade: 'આગામી દાયકા',
    previousCentury: 'છેલ્લી સદી',
    nextCentury: 'આગામી સદી'
}
var localeValues = {
    locale: 'gu',
    Pagination: {
        // Options.jsx
        items_per_page: '/ પાનું',
        jump_to: 'પર જાઓ',
        jump_to_confirm: 'ખાતરી કરો',
        page: '',
        // Pagination.jsx
        prev_page: 'પાછલું પાનું',
        next_page: 'આગામી પાનું',
        prev_5: 'છેલ્લા 5 પૃષ્ઠો',
        next_5: 'આગામી 5 પૃષ્ઠો',
        prev_3: 'છેલ્લા 3 પૃષ્ઠો',
        next_3: 'આગામી 3 પૃષ્ઠો'
    },
    DatePicker: {
        lang: {
            placeholder: 'તારીખ પસંદ કરો',
            yearPlaceholder: 'વર્ષ પસંદ કરો',
            quarterPlaceholder: 'ક્વાર્ટર પસંદ કરો',
            monthPlaceholder: 'મહિનો પસંદ કરો',
            weekPlaceholder: 'અઠવાડિયું પસંદ કરો',
            rangePlaceholder: ['શરૂઆત ની તારીખ', 'અંતિમ તારીખ'],
            rangeYearPlaceholder: ['પ્રારંભિક વર્ષ', 'અંત વર્ષ'],
            rangeMonthPlaceholder: ['પ્રારંભ મહિનો', 'અંત મહિનો'],
            rangeWeekPlaceholder: ['પ્રારંભિક સપ્તાહ', 'અંત સપ્તાહ'],
            ...commonDate
        },
        timePickerLocale: {
            placeholder: 'સમય પસંદ કરો',
            rangePlaceholder: ['પ્રારંભ સમય', 'અંત સમય']
        }


    },
    TimePicker: {
        placeholder: 'સમય પસંદ કરો',
        rangePlaceholder: ['પ્રારંભ સમય', 'અંત સમય']
    },
    Calendar: {
        lang: {
            placeholder: 'તારીખ પસંદ કરો',
            yearPlaceholder: 'વર્ષ પસંદ કરો',
            quarterPlaceholder: 'ક્વાર્ટર પસંદ કરો',
            monthPlaceholder: 'મહિનો પસંદ કરો',
            weekPlaceholder: 'અઠવાડિયું પસંદ કરો',
            rangePlaceholder: ['શરૂઆત ની તારીખ', 'અંતિમ તારીખ'],
            rangeYearPlaceholder: ['પ્રારંભિક વર્ષ', 'અંત વર્ષ'],
            rangeMonthPlaceholder: ['પ્રારંભ મહિનો', 'અંત મહિનો'],
            rangeWeekPlaceholder: ['પ્રારંભિક સપ્તાહ', 'અંત સપ્તાહ'],
            ...commonDate,
        }
    },
    global: {
        placeholder: 'કૃપા કરી પસંદ કરો'
    },
    Table: {
        filterTitle: 'બંધ સૂચિ',
        filterConfirm: 'યોગ્ય રીતે',
        filterReset: 'ફરીથી સેટ કરો',
        filterEmptyText: 'ફિલ્ટર નથી',
        emptyText: 'કોઈ માહિતી નથી',
        selectAll: 'વર્તમાન પૃષ્ઠ પસંદ કરો',
        selectInvert: 'વર્તમાન પૃષ્ઠ ફેરવો',
        selectNone: 'બધા ડેટા સાફ કરો',
        selectionAll: 'બધા ડેટા પસંદ કરો',
        sortTitle: 'દ્વારા સ sortર્ટ',
        expand: 'લાઇન વિસ્તૃત કરો',
        collapse: 'પતન રેખા',
        triggerDesc: 'ઉતરતા ક્રમમાં ગોઠવવા માટે ક્લિક કરો',
        triggerAsc: 'ચડતા ક્રમમાં ગોઠવવા માટે ક્લિક કરો',
        cancelSort: 'સોર્ટિંગ રદ કરવા ક્લિક કરો'
    },
    Modal: {
        okText: 'યોગ્ય રીતે',
        cancelText: 'રદ કરવું',
        justOkText: 'યોગ્ય રીતે'
    },
    Popconfirm: {
        okText: 'યોગ્ય રીતે',
        cancelText: 'રદ કરવું'
    },
    Transfer: {
        titles: ['', ''],
        searchPlaceholder: 'અહીં શોધો',
        itemUnit: 'તત્વ',
        itemsUnit: 'વિષય અનુશાર',
        remove: 'દૂર કરો',
        selectCurrent: 'વર્તમાન પૃષ્ઠ પસંદ કરો',
        removeCurrent: 'વર્તમાન પૃષ્ઠ કા deleteી નાખો',
        selectAll: 'બધા ડેટા પસંદ કરો',
        removeAll: 'બધા ડેટા કા deleteી નાખો',
        selectInvert: 'વર્તમાન પાનું ઉલટાવો'
    },
    Upload: {
        uploading: 'અપલોડ કરી રહ્યા છીએ...',
        removeFile: 'ફાઇલ ને દૂર કરો',
        uploadError: 'અપલોડ કરવામાં ભૂલ',
        previewFile: 'ફાઇલ પૂર્વદર્શન',
        downloadFile: 'ફાઇલ ડાઉનલોડ કરો'
    },
    Empty: {
        description: 'કોઈ ડેટા ઉપલબ્ધ નથી'
    },
    Icon: {
        icon: 'ચિહ્ન'
    },
    Text: {
        edit: 'સંપાદન કરો',
        copy: 'નકલ',
        copied: 'નકલ કરી',
        expand: 'વિસ્તરણ'
    },
    PageHeader: {
        back: 'પરત ફર્યું'
    },
    Form: {
        optional: '(વૈકલ્પિક)',
        defaultValidateMessages: {
            "default": '${label} ક્ષેત્ર ચકાસણી ભૂલ માટે',
            required: 'કૃપા કરીને ${label} દાખલ કરો',
            "enum": '${label} [${enum}] એક હોવું જોઈએ',
            whitespace: '${label} ખાલી પત્ર ન હોઈ શકે',
            date: {
                format: '${label} તારીખ બંધારણ અયોગ્ય છે',
                parse: '${label} આજ સુધી બદલી શકાતુ નથી',
                invalid: '${label} અયોગ્ય તારીખ છે'
            },
            types: {
                string: typeTemplate,
                method: typeTemplate,
                array: typeTemplate,
                object: typeTemplate,
                number: typeTemplate,
                date: typeTemplate,
                "boolean": typeTemplate,
                integer: typeTemplate,
                "float": typeTemplate,
                regexp: typeTemplate,
                email: typeTemplate,
                url: typeTemplate,
                hex: typeTemplate
            },
            string: {
                len: '${label} ${len} પત્રનો હોવો જોઈએ',
                min: '${label} ઓછામાં ઓછું ${min} પાત્રોના હોવા જોઈએ',
                max: '${label} મહત્તમ ${max} પાત્રોના હોવા જોઈએ',
                range: '${label} ${min}-${max} પાત્રો વચ્ચે હોવું આવશ્યક છે'
            },
            number: {
                len: '${label} ${len} સમાન હોવું જોઈએ',
                min: '${label} ઓછામાં ઓછું ${min} હોવું જોઈએ',
                max: '${label} મહત્તમ ${max} હોવું જોઈએ',
                range: '${label} ${min}-${max} વચ્ચે હોવું જોઈએ'
            },
            array: {
                len: '${len} ${label} હોવું જોઈએ',
                min: 'ઓછામાં ઓછું ${min} ${label}',
                max: 'મહત્તમ ${max} ${label}',
                range: '${label} માત્રા ${min}-${max} વચ્ચે હોવું જોઈએ'
            },
            pattern: {
                mismatch: '${label} ${pattern} પેટર્ન મેળ ખાતી નથી'
            }
        }
    },
    Image: {
        preview: 'પૂર્વદર્શન'
    }
};

export default localeValues