import antdInHi from 'antd/es/locale/hi_IN';
import taMsg from "../locales/in_TA.json";

const TaLang = {
  antd: antdInHi,
  locale: 'in-TA',
  messages: {
    ...taMsg
  },
};
export default TaLang;
