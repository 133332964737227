import { APP_PREFIX_PATH } from "configs/AppConfig";
import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper"
import EnergyServices from "services/Energy/index";
import countries from "assets/data/countries.json"
import MasterService from "services/MasterServices/index";
import PlanService from "services/Plans/index";
import { env } from "configs/EnvironmentConfig";
const commonOrderBy = {
    orderBy: "created_at", sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} clients`
}

class EnergyStore {
    store;
    @observable availableTabs = [
        {
            key: "active",
            tab: `Active`,
        },
        {
            key: "deactive",
            tab: `Deactive`,
        },
        {
            key: "pending",
            tab: `Pending`,
        },
    ];
    @observable activeTab = null;
    @observable tableLoading = false

    @observable activeTableData = []
    @observable activeTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        energy_status: 2,
    };
    @observable pendingTableData = []
    @observable pendingTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        energy_status: 1,
    };
    @observable deactiveTableData = []
    @observable deactiveTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        energy_status: 3,
    };

    @observable addEditHospitalRecord = {};
    @observable addEditHospitalModalVisible = false
    @observable submittingForm = false
    @observable currentStep = 0;
    @observable productRecord = {};
    @observable planLists = [];
    @observable planLevelLists = [];
    @observable disabledAddServiceControl = false;
    @observable masterRoleParentTableData = [];
    @observable plan_name = "";
    @observable plan_detail = {};

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    saveValues = (values) => {
        this.productRecord = update(this.productRecord, {
            $set: {...this.productRecord, ...values}
        })
    }

    nextStep = (value) => {
        this.currentStep = value;
    }

    setActiveTab = (tab = "active", push = false) => {
        this.activeTab = tab;
        if (push) {
            this.store.router.history.push(`${APP_PREFIX_PATH}/energy/${tab}`)
        }
        switch (tab) {
            case "deactive":
                this.getHospitalsList(tab, this.deactiveTablePagination)
                break;
            case "pending":
                this.getHospitalsList(tab, this.pendingTablePagination)
                break;
            default:
                this.getHospitalsList(tab, this.activeTablePagination)
                break;
        }
    }

    setValue = (key, value) => {
        this[key] = value;
    }

    handleChangeAccessControlData = (index, key, value) => {
        this.serviceLevelLists = update(this.serviceLevelLists, {
          [index]: {
            [key]: { $set: value },
          },
        });
    };

    handleAddAccessControl = () => {
        
        this.planLevelLists = update(this.planLevelLists, {
            $push: [
            {
                ds_id: undefined,
                ds_name: undefined,
                plan_service_price_base: undefined,
                plan_service_price: undefined,
                plan_service_per_unit_price: undefined,
                plan_service_unit_free: undefined,
                isEdit: true,
                id: Date.now() + Math.random()
            },
            ],
        });
        this.disabledAddServiceControl = true;
    };

    handleRemoveAccessControls = flow( function* (index) {
        let levelObject = this.planLevelLists[index];        
        if (levelObject && levelObject.plan_service_id) {
            this.isBtnLoading = true;
            const dataObject = {
                ...this.store.commonStore.getDefaultParameter(),
                plan_service_id: levelObject.plan_service_id,
                so_id: 1
            };
            const res = yield EnergyServices.deletePlanService(dataObject);
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(res)
            this.planLevelLists = update(this.planLevelLists, {
                $splice: [[index, 1]],
            });
            
        } else {
          this.planLevelLists = update(this.planLevelLists, {
            $splice: [[index, 1]],
          });
        }
        this.disabledAddServiceControl = false;
    });

    getHospitalsList = flow(function* (tab, pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all
            }
            const { data } = yield EnergyServices.EnergyList(dataObjectForServer)
            switch (tab) {
                case "deactive":
                    this.deactiveTablePagination = update(this.deactiveTablePagination, {
                        total: {
                            $set: data.total
                        }
                    })
                    this.deactiveTableData = update(this.deactiveTableData, {
                        $set: data.rows
                    })
                    break;
                case "pending":
                    this.pendingTablePagination = update(this.pendingTablePagination, {
                        total: {
                            $set: data.total
                        }
                    })
                    this.pendingTableData = update(this.pendingTableData, {
                        $set: data.rows
                    })
                    break;
                default:
                    this.activeTablePagination = update(this.activeTablePagination, {
                        total: {
                            $set: data.total
                        }
                    })
                    this.activeTableData = update(this.activeTableData, {
                        $set: data.rows
                    })
                    break;
            }
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    getPlansList = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = true) {
        try {
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all
            }
            const { data } = yield PlanService.plansList(this.store.commonStore.product_name, dataObjectForServer)
            this.planLists = update(this.planLists, {
                $set: data.rows
            })
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                switch (this.activeTab) {
                    case "deactive":
                        this.deactiveTablePagination = update(this.deactiveTablePagination, objToUpdate)
                        this.getHospitalsList(this.activeTab, this.deactiveTablePagination)
                        break;
                    case "pending":
                        this.pendingTablePagination = update(this.pendingTablePagination, objToUpdate)
                        this.getHospitalsList(this.activeTab, this.pendingTablePagination)
                        break;
                    default:
                        this.activeTablePagination = update(this.activeTablePagination, objToUpdate)
                        this.getHospitalsList(this.activeTab, this.activeTablePagination)
                        break;
                }
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                switch (this.activeTab) {
                    case "deactive":
                        this.deactiveTablePagination = update(this.deactiveTablePagination, sortToUpdate)
                        this.getHospitalsList(this.activeTab, this.deactiveTablePagination)
                        break;
                    case "pending":
                        this.pendingTablePagination = update(this.pendingTablePagination, sortToUpdate)
                        this.getHospitalsList(this.activeTab, this.pendingTablePagination)
                        break;
                    default:
                        this.activeTablePagination = update(this.activeTablePagination, sortToUpdate)
                        this.getHospitalsList(this.activeTab, this.activeTablePagination)
                        break;
                }
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        switch (this.activeTab) {
            case "deactive":
                this.deactiveTablePagination = update(this.deactiveTablePagination, updateObject)
                if (!value) {
                    this.onSearch(value)
                }
                break;
            case "pending":
                this.pendingTablePagination = update(this.pendingTablePagination, updateObject)
                if (!value) {
                    this.onSearch(value)
                }
                break;
            default:
                this.activeTablePagination = update(this.activeTablePagination, updateObject)
                if (!value) {
                    this.onSearch(value)
                }
                break;
        }
    }

    @action getMasterParentServiceLists = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all,
                ds_for: 2
            }
            const { data } = yield MasterService.servicesList(this.store.commonStore.product_name, dataObjectForServer)
            let finalRecords = data.rows && data.rows.length > 0 && data.rows.filter((item) => item.ds_parent_id === null)
            this.masterRoleParentTableData = update(this.masterRoleParentTableData, {
                $set: finalRecords
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        switch (this.activeTab) {
            case "deactive":
                this.deactiveTablePagination = update(this.deactiveTablePagination, updateObject)
                this.getHospitalsList(this.activeTab, this.deactiveTablePagination)
                break;
            case "pending":
                this.pendingTablePagination = update(this.pendingTablePagination, updateObject)
                this.getHospitalsList(this.activeTab, this.pendingTablePagination)
                break;
            default:
                this.activeTablePagination = update(this.activeTablePagination, updateObject)
                this.getHospitalsList(this.activeTab, this.activeTablePagination)
                break;
        }
    }

    @action handleAddEditHospitalModal = (visible, record = {}, form) => {
        this.addEditHospitalModalVisible = visible;
        this.addEditHospitalRecord = record;
        if (visible === false) {
            this.plan_detail = {};
            this.plan_name = "";
            this.planLevelLists = [];
            this.currentStep = 0;
            this.disabledAddServiceControl = false;
            form && form.resetFields();
        } else {
            this.plan_name = record.energy_plan_name;
            this.planLevelLists = record.services;
            let planRecord = this.planLists && this.planLists.find(item => item.plan_id === record.plan_id)
            this.plan_detail = planRecord ? planRecord : {}
        }
    }

    @action handleSaveHospital = flow(function* (values, form) {
        this.submittingForm = true
        try {
            let findCountryRecord = countries.find(item => item.code === this.productRecord.energy_country_code)
            const dataObj = {
                register_callback_url: env.ENERGY_FRONTEND + env.SET_PASSWORD, // "http://localhost:3001/set-password",
                ...this.store.commonStore.getDefaultParameter(),
                ...this.productRecord,
                energy_country: findCountryRecord && findCountryRecord.name ? findCountryRecord.name : "India",
                energy_tz: findCountryRecord && findCountryRecord.timezone ? findCountryRecord.timezone : "Asia/Kolkata",
                plan_id: Number(values.plan_id),
                services: this.planLevelLists.length && this.planLevelLists,
                energy_plan_name: this.plan_name === "custom_plan" ? "Custom Plan - " + this.productRecord.energy_name : this.plan_name,
                consider_price: Object.keys(this.plan_detail).length > 0 ? this.plan_detail.consider_price : false,
                plan_price: Object.keys(this.plan_detail).length > 0 ? Number(this.plan_detail.plan_price) : 0,
                free_days: Object.keys(this.plan_detail).length > 0 ? this.plan_detail.free_days : false,
                allow_free_days: Object.keys(this.plan_detail).length > 0 ? this.plan_detail.allow_free_days : false
            }
            if(this.addEditHospitalRecord && this.addEditHospitalRecord.energy_id) {
                dataObj.energy_id = this.addEditHospitalRecord.energy_id
            }
            yield EnergyServices.saveEnergy(dataObj)
            this.submittingForm = false
            this.addEditHospitalModalVisible = false;
            form && form.resetFields();
            this.plan_detail = {};
            this.plan_name = "";
            this.planLevelLists = [];
            this.currentStep = 0;
            this.disabledAddServiceControl = false;
            switch (this.activeTab) {
                case "deactive":
                    this.getHospitalsList(this.activeTab, this.deactiveTablePagination)
                    break;
                case "pending":
                    this.getHospitalsList(this.activeTab, this.pendingTablePagination)
                    break;
                default:
                    this.getHospitalsList(this.activeTab, this.activeTablePagination)
                    break;
            }
        } catch (error) {
            this.submittingForm = false
            this.store.commonStore.responseMessageHandler(error)
        }
    })

}

export default EnergyStore