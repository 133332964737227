import React from "react";
import { Menu, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavProfile from './NavProfile';
import NavOrg from "./NavOrganization";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import { observer } from "mobx-react";
import Utils from "utils/index";

const { Header } = Layout;

const HeaderNav = props => {
  const {
    themeStore: {
      headerNavColor,
      navCollapsed,
      navType,
      mobileNav,
      toggleCollapsedNav,
      onMobileNavToggle,
    },
  } = props;
  const isMobile = Utils.isMobile()
  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = utils.getColorContrast(headerNavColor)
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }
  return (
    <Header className={`app-header dark`} >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <Menu.Item key="0" onClick={() => { onToggle() }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </Menu.Item>
              }
            </Menu>
          </div>
          <div className="nav-right">
            <NavProfile {...props} />
          </div>
          <NavOrg {...props} />
        </div>
      </div>
    </Header>
  )
}

export default observer(HeaderNav);