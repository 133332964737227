import React from 'react'
import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'

const ErrorOne = (props) => {
	return (
		<div className="h-100 bg-white">
			<div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1" style={{ textAlign: "center"}}>
				<div>
					<img className="img-fluid" src={require("assets/images/logo.png")} alt="" style={{ height: '100px'}}/>
				</div>
				<div className="container" style={{ textAlign: "center"}}>
					<Row align="middle">
						<Col xs={24} sm={24} md={24}>
							<h1 className="font-weight-bold mb-4 display-4">Page not found</h1>
							<p className="font-size-md mb-4">We've noticed you lost your way, no worries, we will help you to found the correct path.</p>
							<Link to={props.redirect}>
								<Button 
									type="primary" 
									icon={<ArrowLeftOutlined />}
									onClick={props.history.goBack}
								>Go back</Button>
							</Link>
						</Col>
						<Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
							<img className="img-fluid mt-md-0 mt-4" src="/img/others/img-20.png" alt="" />
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

export default ErrorOne
