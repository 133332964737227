import fetch from 'auth/FetchInterceptor'

const CareerCategoryService = {}
const careerCategoryRoute = '/career-category/'

CareerCategoryService.getCareerCategoryList = function (params) {
    return fetch({
        url: careerCategoryRoute + 'list',
        method: 'post',
        data: params,
    })
}

CareerCategoryService.ParentCategoryLists = function (params) {
    return fetch({
        url: careerCategoryRoute + 'parent-category',
        method: 'post',
        data: params,
    })
}

CareerCategoryService.SubCategoryLists = function (params) {
    return fetch({
        url: careerCategoryRoute + 'sub-category',
        method: 'post',
        data: params,
    })
}

CareerCategoryService.addUpdateCareerCategory = function (params) {
    return fetch({
        url: careerCategoryRoute + 'create',
        method: 'post',
        data: params,
    })
}

CareerCategoryService.deleteCareerCategory = function (params) {
    return fetch({
        url: careerCategoryRoute + 'delete',
        method: 'delete',
        data: params,
    })
}
export default CareerCategoryService