import React from "react";
import ActionButtons from "shared-components/ActionButtons/index";
import { Tag } from "antd";

const staticTableColumns = (formatMessage) => [
    {
        title: formatMessage({ id: "sub_career_name"}),
        dataIndex: "career_post_name",
        key: "career_post_name",
        width: 200,
        sorter: true,
        ellipsis: true,
        render: (_, elm) => elm.career_post_name
    },
    {
        title: formatMessage({ id: "sub_career_category_active"}),
        dataIndex: "career_category_active",
        key: "career_category_active",
        width: 100,
        sorter: true,
        ellipsis: true,
        render: (_, elm) => {
            if(_) {
                return <Tag color="#87d068">{formatMessage({ id: "active"})}</Tag>
            }
            return <Tag color="red">{formatMessage({ id: "deactive"})}</Tag>
        }
    },
]

export const ExpandableColumns = (props) => {
    const {
        careerStore,
        intl: {
            formatMessage
        } 
    } = props;
    return [
        ...staticTableColumns(formatMessage),
        {
                title: 'Actions',
                dataIndex: 'actions',
                width: 100,
                render: (_, elm) => (
                    <div className="text-right">
                        <ActionButtons 
                            onEdit={() => careerStore.handleVisibleAddEditCareerDrawer(true, elm)}
                            popconfirmTitle={'Are you sure you want to delete this record?'}
                            onRemove={() => careerStore.handleRemoveCategory(elm)}
                        />
                    </div>
                )
        }
    ]
}