import React from "react";
import { Route } from "react-router-dom";
import { observer, inject } from "mobx-react";
/*import Loadable from "react-loadable";
const Loader = Loadable({
  loader: () => import("globals/components/loader"),
  loading: () => null
});
const NoMatch = Loadable({
  loader: () => import("../../modules/noMatch"),
  loading: () => <Loader />
});*/
const Permission = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // const {
      //   // globals: { allow_routes, chaeckRoutePermission, checkVersionFunction },
      //   location = {}
      // } = rest;
      // checkVersionFunction()
      // if (chaeckRoutePermission(location.pathname)) {
        return (
          <>
            <Component {...props} />
          </>
        );
      // } else {
      //   rest.globals.checkPermissionToRedirect();
      //   return null;
      // }
    }}
  />
);

export default inject("commonStore", "router")(observer(Permission));
