import {
  ShopOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const OrganizationRoutes = [
  {
    key: 'dairy',
    path: `${APP_PREFIX_PATH}/dairy`,
    title: 'dairy',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [2],
  },
  {
    key: 'energy',
    path: `${APP_PREFIX_PATH}/energy`,
    title: 'energy',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [3],
  },
  {
    key: 'roles',
    path: `${APP_PREFIX_PATH}/roles`,
    title: 'roles',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1,2,3,4],
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'users',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1],
  },
  {
    key: 'services',
    path: `${APP_PREFIX_PATH}/services`,
    title: 'services',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1,2,3,4],
  },
  {
    key: 'plans',
    path: `${APP_PREFIX_PATH}/plans`,
    title: 'plans',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [2,3,4],
  },
  {
    key: 'access_controls',
    path: `${APP_PREFIX_PATH}/access-controls`,
    title: 'access_controls',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1,2,3,4],
  },
  {
    key: 'career_category',
    path: `${APP_PREFIX_PATH}/career-category`,
    title: 'career_category',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1],
  },
  {
    key: 'career_list',
    path: `${APP_PREFIX_PATH}/career-list`,
    title: 'career_list',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1],
  },
  {
    key: 'blog',
    path: `${APP_PREFIX_PATH}/blog-management`,
    title: 'blog',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1],
  },
  {
    key: 'products',
    path: `${APP_PREFIX_PATH}/products`,
    title: 'products',
    icon: ShopOutlined,
    breadcrumb: false,
    access_control: [1910],
    service_id: [1910],
    submenu: [],
    product_id: [1],
  }
]


const sideMenuConfig = [
  ...OrganizationRoutes,
]

export default sideMenuConfig;