

import fetch from 'auth/FetchInterceptor'

const UsersService = {}
const usersRoute = '/user/'

UsersService.usersList = function (pathname, params) {
    return fetch({
        url: usersRoute + pathname + '/list',
        method: 'post',
        data: params,
    })
}

UsersService.addUpdateUser = function (pathname, params) {
    return fetch({
        url: usersRoute + pathname + '/add',
        method: 'post',
        data: params,
    })
}

UsersService.deleteUser = function (pathname, params) {
    return fetch({
        url: usersRoute + pathname + '/delete',
        method: 'delete',
        data: params,
    })
}

export default UsersService