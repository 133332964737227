import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'shared-components/Loading';
import Loadable from "react-loadable";
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

const Login = Loadable({
  loader: () => import("./authentication/login"),
  loading: () => <Loading cover="page" />,
});

const ForgetPassword = Loadable({
  loader: () => import("./authentication/forgot-password"),
  loading: () => <Loading cover="page" />,
});

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={Login}
        />
        <Route 
          path={`${AUTH_PREFIX_PATH}/forgot-password`} 
          component={ForgetPassword} 
        />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/set-password/:token`} component={lazy(() => import(`./authentication/set-password`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

