import React from "react";
import { Layout } from 'antd';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import {  observer } from "mobx-react";
import { injectIntl } from "react-intl";

const { Sider } = Layout;

 const SideNav = (props) => {
  const { themeStore: { navCollapsed, sideNavTheme, } } = props
  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''}`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
      onBreakpoint={(broken) => {
        props.themeStore.setIsMobile(broken);
      }}
    >
      <Scrollbars autoHide>
        <MenuContent
          type={NAV_TYPE_SIDE}
          {...props}
        />
      </Scrollbars>
    </Sider>
  )
}

export default injectIntl(observer(SideNav));