

import fetch from 'auth/FetchInterceptor'

const MasterService = {}
const serviceRoute = '/service/'

MasterService.servicesList = function (pathname, params) {
    return fetch({
        url: serviceRoute + pathname + '/list',
        method: 'post',
        data: params,
    })
}

MasterService.addUpdateService = function (pathname, params) {
    return fetch({
        url: serviceRoute + pathname +  '/create',
        method: 'post',
        data: params,
    })
}

MasterService.deleteService = function (pathname, params) {
    return fetch({
        url: serviceRoute + pathname +  '/delete',
        method: 'delete',
        data: params,
    })
}

export default MasterService