import React from "react";
import { Drawer } from "antd";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import Logo from "./Logo";
import { observer } from "mobx-react"
import Flex from "../../Flex";
import { ArrowLeftOutlined } from "@ant-design/icons";

const MobileNav = (props) => {
  const {
    themeStore: { onMobileNavToggle,  mobileNav },
  } = props

  const onClose = () => {
    onMobileNavToggle(false);
  };
  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};
export default observer(MobileNav);
