import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper"
import { ROLE_FOR } from "constants/App/RoleConstant";
import UsersService from "services/Users/index";
const commonOrderBy = {
    orderBy: "created_at",
    sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`
}

class UsersStore {
    store;
    @observable tableLoading = false

    @observable masterUserTableData = []
    @observable masterUserTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        role_for: ROLE_FOR.SYSTEM_OWNERS,
    };
    @observable isUserModal = false;
    @observable userRecord = {};
    @observable isBtnLoading = false;

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setActiveTab = () => {
        this.getUsersList(this.masterUserTablePagination)
    }

    getUsersList = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all
            }
            const { data } = yield UsersService.usersList(this.store.commonStore.product_name,dataObjectForServer)
            this.masterUserTablePagination = update(this.masterUserTablePagination, {
                total: {
                    $set: data.total
                }
            })
            this.masterUserTableData = update(this.masterUserTableData, {
                $set: data.rows
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })
    
    @action handleRemoveUser = flow(function *(rowRecord){
        try {
            this.tableLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                action_so_id: rowRecord.so_id
            }
            const { data } = yield UsersService.deleteUser(this.store.commonStore.product_name,dataObjectForServer)
            this.store.commonStore.responseMessageHandler(data)
            this.tableLoading = false;
            this.getUsersList(this.masterUserTablePagination)
            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleAddEditUser = flow(function *(values, form) {
        try {
            this.isBtnLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                so_fname: values.so_fname,
                so_lname: values.so_lname,
                so_email: values.so_email,
                so_password: values.so_password,
                role_id: values.role_id,
            }
            if (this.userRecord) {
                dataObjectForServer.action_so_id = this.userRecord.so_id
            }
            const { data } = yield UsersService.addUpdateUser(this.store.commonStore.product_name, dataObjectForServer)
            this.isBtnLoading = false
            this.isUserModal = false;
            form && form.resetFields();
            this.userRecord = {};
            this.store.commonStore.responseMessageHandler(data)
            this.getUsersList(this.masterUserTablePagination)
            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleVisibleAddEditUserDrawer = (visible, record = {}, form) => {
        console.log("record ============", record);
        this.isUserModal = visible;
        this.userRecord = record;
        if(visible === false) {
            form && form.resetFields();
        }
    }

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                this.masterUserTablePagination = update(this.masterUserTablePagination, objToUpdate)
                this.getUsersList(this.masterUserTablePagination)
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                this.masterUserTablePagination = update(this.masterUserTablePagination, sortToUpdate)
                this.getUsersList(this.masterUserTablePagination)
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        this.masterUserTablePagination = update(this.masterUserTablePagination, updateObject)
        if (!value) {
            this.onSearch(value)
        }
    }

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        this.masterUserTablePagination = update(this.masterUserTablePagination, updateObject)
        this.getUsersList(this.masterUserTablePagination)
    }

}

export default UsersStore