import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { toJS } from "mobx";
import { Provider } from "mobx-react";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Stores from 'stores/index';
import "./index.scss";

ReactDOM.render(
  <Provider {...new Stores()}>
    <Router>
      <div className="App">
        <App />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (process.env.REACT_APP_ENV === "prod") {
  window.log_console = false;
  window.console = (function (oldCons) {
    return {
      ...oldCons,
      log: function (...args) { },
      table: function (...args) { },
      error: function (...args) {
        const newArgs = args.map((param) => {
          return toJS(param);
        });
        window.log_console_error && oldCons.error(...newArgs);
      }
    };
  })(window.console);
} else {
  window.log_console_error = true;
  if (process.env.REACT_APP_ENV !== "local") {
    window.log_console = true;
  }
  window.console = (function (oldCons) {
    return {
      ...oldCons,
      log: function (...args) {
        const newArgs = args.map((param) => {
          return toJS(param);
        });
        window.log_console && oldCons.log(...newArgs);
      },
      table: function (...args) {
        const newArgs = args.map((param) => {
          return toJS(param);
        });
        window.log_console && oldCons.table(...newArgs);
      },
      error: function (...args) {
        const newArgs = args.map((param) => {
          return toJS(param);
        });
        window.log_console_error && oldCons.error(...newArgs);
      }
    };
  })(window.console);
}
