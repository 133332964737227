import { observable, makeAutoObservable, flow,  action } from "mobx";
import AuthService from "services/AuthService";

class AuthStore {
    store;
    @observable loadingButton = false;
    @observable message = null;
    @observable showMessage = false;
    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    signIn = flow(function* (values) {
        this.loadingButton = true;
        try {
            const response = yield AuthService.login(values);
            this.loadingButton = false;
            this.store.commonStore.handleUserLoggedIn(true, response.data)
            this.checkUserData(response)
        } catch (error) {
            this.loadingButton = false;
        }
        this.loadingButton = false;
    })

    signOut = (pushHistory = true) => {
        this.store.commonStore.clearAllCookies()
        //this.store.commonStore.resetStore()
        pushHistory && this.store.routes.history.push('/auth/login')
    }


    @action checkUserData = async ({ data: { so_id, products } }) => {
        if (so_id) {
            this.store.commonStore.initializeApp(
                {
                    so_id,
                    products
                },
                () => {
                    this.store.commonStore.checkPermissionToRedirect();
                }
            );
        } else {
            if (products && products.length > 0) {
                this.store.commonStore.initializeApp(
                    {
                        so_id,
                        products
                    },
                    () => {
                        this.store.commonStore.checkPermissionToRedirect();
                    }
                );
            }
            
        }
        

    };
}

export default AuthStore