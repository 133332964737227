

import fetch from 'auth/FetchInterceptor'

const PlanService = {}
const planRoute = '/plans/'

PlanService.plansList = function (pathname, params) {
    return fetch({
        url: planRoute + pathname + '/list',
        method: 'post',
        data: params,
    })
}

PlanService.addUpdatePlan = function (pathname, params) {
    return fetch({
        url: planRoute + pathname + '/create',
        method: 'post',
        data: params,
    })
}

PlanService.deletePlan = function (pathname, params) {
    return fetch({
        url: planRoute + pathname + '/delete',
        method: 'delete',
        data: params,
    })
}

export default PlanService