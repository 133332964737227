import React from 'react';
import { Layout, Grid } from 'antd';
import { observer } from 'mobx-react';
import SideNav from '../layout-components/SideNav';
import MobileNav from '../layout-components/MobileNav'
import HeaderNav from '../layout-components/HeaderNav';
import PageHeader from '../layout-components/PageHeader';
import Footer from '../layout-components/Footer';
import Views from "views/app-views"
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP
} from 'constants/ThemeConstant';
import utils from 'utils';
// import PDFViewer from 'views/PDFViewer/index';
import sideMenu from 'configs/SideMenuConfig';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AppLayout = (props) => {
  const { 
    themeStore: { 
      navCollapsed, 
      navType 
    }, 
    location, 
    commonStore: {
      user
    }
    // commonModal: { visiblePDFViewer } 
  } = props
  const currentRouteInfo = utils.getRouteInfo(sideMenu, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }
  return (
    <Layout>
      <HeaderNav isMobile={isMobile} user={user} {...props} />
      {/* {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} {...props}/> : null} */}
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} {...props} /> : null}
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} breadcrumbTitle={currentRouteInfo?.breadcrumbTitle || false} />
            <Content>
              <Views {...props}/>
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav {...props} />}
      {/* {visiblePDFViewer && (
        <PDFViewer {...props} />
      )} */}
    </Layout>
  )
}

export default observer(AppLayout)