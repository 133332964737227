import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AppLayout from "shared-components/layouts/app-layout/index";
import AuthLayout from 'shared-components/layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { inject, observer } from "mobx-react";
import { Component } from "react";
// import WithoutLayout from "layouts/app-layout/withoutLayout";
import NotFoundError from "./views/auth-views/errors/error-page-1"
function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

class Views extends Component {
  componentDidMount() {
    this.props.router.setRoute(this.props.location, this.props.match, this.props.history);
  }
  render() {
    const { location, themeStore: { locale }, commonStore: { token, so_id, reload } } = this.props;
    const currentAppLocale = AppLocale[locale];
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <ConfigProvider locale={currentAppLocale.antd}>
          <Switch>
            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout />
            </Route>
            

            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token && so_id}>
              {!reload && <AppLayout location={location} {...this.props} />}
            </RouteInterceptor>
            <Route path={"/404"}>
              <NotFoundError {...this.props}/>
            </Route>
          </Switch>
        </ConfigProvider>
      </IntlProvider>
    )
  }
}

export default inject("themeStore", "router", "commonStore")(withRouter(observer(Views)))