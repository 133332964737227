

import fetch from 'auth/FetchInterceptor'

const RolesService = {}
const rolesRoute = '/roles/'

RolesService.rolesList = function (pathname, params) {
    return fetch({
        url: rolesRoute + pathname + '/list',
        method: 'post',
        data: params,
    })
}

RolesService.addUpdateRole = function (pathname, params) {
    return fetch({
        url: rolesRoute + pathname + '/create',
        method: 'post',
        data: params,
    })
}
export default RolesService