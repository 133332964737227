import React from "react"
import { Select } from "antd";
import { observer } from "mobx-react";
const Option = Select;
const NavOrg = (props) => {
  const {
    commonStore: { product_id, products, setProductName },
  } = props;
  //console.log("products ============", products);
  return (
    <div
      className={`nav-search nav-search-active`}
      style={{ marginLeft: "2.5rem" }}
    >
      <div className="d-flex align-items-center w-100">
        <Select
          placeholder={"Please select product"}
          value={product_id}
          onChange={(value, props) => {
			console.log("props", props)  
			setProductName(value, props.product_name)
		  }}
        >
          {products &&
            products.length > 0 &&
            products.map((item, index) => {
              return (
                <Option
                  key={"product_" + index}
                  value={item.product_id}
                  product_name={item.product_route || item.product_name}
                >
                  {item.product_name}
                </Option>
              );
            })}
        </Select>
      </div>
    </div>
  );
};
export default observer(NavOrg);
