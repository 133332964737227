/* eslint-disable no-template-curly-in-string */
var typeTemplate = '${label} वैध ${type} नाही';
const commonDate = {
    locale: 'in-MA',
    today: 'आज',
    now: 'आता',
    backToToday: 'तारखेपर्यंत',
    ok: 'ठीक आहे',
    clear: 'स्पष्ट',
    month: 'महिना',
    year: 'वर्ष',
    timeSelect: 'वेळ निवडा',
    dateSelect: 'तारीख निवडा',
    weekSelect: 'एक आठवडा निवडा',
    monthSelect: 'एक महिना निवडा',
    yearSelect: 'एक वर्ष निवडा',
    decadeSelect: 'एक दशक निवडा',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthBeforeYear: true,
    previousMonth: 'मागील महिन्यात (पान अप)',
    nextMonth: 'पुढील महिन्यात (खाली पान)',
    previousYear: 'मागील वर्ष (Ctrl + डावे)',
    nextYear: 'पुढील वर्ष (Ctrl + उजवीकडे)',
    previousDecade: 'गेल्या दशकात',
    nextDecade: 'पुढील दशक',
    previousCentury: 'गेल्या शतकात',
    nextCentury: 'पुढील शतक'
}
var localeValues = {
    locale: 'ma',
    Pagination: {
        // Options.jsx
        items_per_page: '/ पान',
        jump_to: 'पुढे जा',
        jump_to_confirm: 'पुष्टी',
        page: '',
        // Pagination.jsx
        prev_page: 'मागील पान',
        next_page: 'पुढील पान',
        prev_5: 'शेवटची 5 पृष्ठे',
        next_5: 'पुढील 5 पृष्ठे',
        prev_3: 'शेवटची 3 पृष्ठे',
        next_3: 'पुढील 3 पृष्ठे'
    },
    DatePicker: {
        lang: {
            placeholder: 'तारीख निवडा',
            yearPlaceholder: 'वर्ष निवडा',
            quarterPlaceholder: 'चतुर्थांश निवडा',
            monthPlaceholder: 'महिना निवडा',
            weekPlaceholder: 'आठवडा निवडा',
            rangePlaceholder: ['आरंभ तारीख', 'शेवटची तारीख'],
            rangeYearPlaceholder: ['प्रारंभिक वर्ष', 'शेवटचे वर्ष'],
            rangeMonthPlaceholder: ['प्रारंभ महिना', 'शेवटचा महिना'],
            rangeWeekPlaceholder: ['सुरूवातीस आठवडा', 'शेवटचा आठवडा'],
            ...commonDate
        },
        timePickerLocale: {
            placeholder: 'वेळ निवडा',
            rangePlaceholder: ['सुरवातीची वेळ', 'समाप्तीचा कालावधी']
        }


    },
    TimePicker: {
        placeholder: 'वेळ निवडा',
        rangePlaceholder: ['सुरवातीची वेळ', 'समाप्तीचा कालावधी']
    },
    Calendar: {
        lang: {
            placeholder: 'तारीख निवडा',
            yearPlaceholder: 'वर्ष निवडा',
            quarterPlaceholder: 'चतुर्थांश निवडा',
            monthPlaceholder: 'महिना निवडा',
            weekPlaceholder: 'आठवडा निवडा',
            rangePlaceholder: ['आरंभ तारीख', 'शेवटची तारीख'],
            rangeYearPlaceholder: ['प्रारंभिक वर्ष', 'शेवटचे वर्ष'],
            rangeMonthPlaceholder: ['प्रारंभ महिना', 'शेवटचा महिना'],
            rangeWeekPlaceholder: ['सुरूवातीस आठवडा', 'शेवटचा आठवडा'],
            ...commonDate,
        }
    },
    global: {
        placeholder: 'कृपया निवडा'
    },
    Table: {
        filterTitle: 'बंद यादी',
        filterConfirm: 'व्यवस्थित',
        filterReset: 'रीसेट करा',
        filterEmptyText: 'फिल्टर नाही',
        emptyText: 'माहिती नाही',
        selectAll: 'वर्तमान पृष्ठ निवडा',
        selectInvert: 'वर्तमान पृष्ठ फिरवा',
        selectNone: 'सर्व डेटा साफ करा',
        selectionAll: 'सर्व डेटा निवडा',
        sortTitle: 'क्रमवारी लावा',
        expand: 'रेखा विस्तृत करा',
        collapse: 'संकुचित ओळ',
        triggerDesc: 'उतरत्या क्रमवारी लावण्यासाठी क्लिक करा',
        triggerAsc: 'चढत्या क्रमवारी लावण्यासाठी क्लिक करा',
        cancelSort: 'क्रमवारी रद्द करण्यासाठी क्लिक करा'
    },
    Modal: {
        okText: 'व्यवस्थित',
        cancelText: 'रद्द करण्यासाठी',
        justOkText: 'व्यवस्थित'
    },
    Popconfirm: {
        okText: 'व्यवस्थित',
        cancelText: 'रद्द करण्यासाठी'
    },
    Transfer: {
        titles: ['', ''],
        searchPlaceholder: 'येथे शोध',
        itemUnit: 'घटक',
        itemsUnit: 'विषय',
        remove: 'काढा',
        selectCurrent: 'वर्तमान पृष्ठ निवडा',
        removeCurrent: 'वर्तमान पृष्ठ हटवा',
        selectAll: 'सर्व डेटा निवडा',
        removeAll: 'सर्व डेटा हटवा',
        selectInvert: 'उलट पृष्ठ'
    },
    Upload: {
        uploading: 'अपलोड करीत आहे ...',
        removeFile: 'अर्क फाइल',
        uploadError: 'अपलोड त्रुटी',
        previewFile: 'फाइल पूर्वावलोकन',
        downloadFile: 'फाईल डाउनलोड करा'
    },
    Empty: {
        description: 'माहिती उपलब्ध नाही'
    },
    Icon: {
        icon: 'चिन्ह'
    },
    Text: {
        edit: 'सुधारणे',
        copy: 'प्रत',
        copied: 'कॉपी केले',
        expand: 'तपशीलवार'
    },
    PageHeader: {
        back: 'परत'
    },
    Form: {
        optional: '(पर्यायी)',
        defaultValidateMessages: {
            "default": '${label} यासाठी फील्ड प्रमाणीकरण त्रुटी',
            required: 'कृपया ${label} प्रविष्ट करा',
            "enum": '${label} [${enum}] त्यापैकी एक असणे आवश्यक आहे',
            whitespace: '${label} रिक्त वर्ण असू शकत नाही',
            date: {
                format: '${label} तारीख स्वरूप अवैध आहे',
                parse: '${label} तारखेला रूपांतरित केले जाऊ शकत नाही',
                invalid: '${label} ही अवैध तारीख आहे'
            },
            types: {
                string: typeTemplate,
                method: typeTemplate,
                array: typeTemplate,
                object: typeTemplate,
                number: typeTemplate,
                date: typeTemplate,
                "boolean": typeTemplate,
                integer: typeTemplate,
                "float": typeTemplate,
                regexp: typeTemplate,
                email: typeTemplate,
                url: typeTemplate,
                hex: typeTemplate
            },
            string: {
                len: '${label} ${len} पत्र असणे आवश्यक आहे',
                min: '${label} किमान ${min} वर्ण असणे आवश्यक आहे',
                max: '${label} जास्तीत जास्त ${max} वर्ण असणे आवश्यक आहे',
                range: '${label} ${min}-${max} वर्णांमधील असणे आवश्यक आहे'
            },
            number: {
                len: '${label} ${len} च्या बरोबरीने असावे',
                min: '${label} किमान ${min} पाहिजे',
                max: '${label} जास्तीत जास्त ${max} पाहिजे',
                range: '${label} ${min}-${max} दरम्यान असावे'
            },
            array: {
                len: '${len} ${label} पाहिजे',
                min: 'किमान ${min} ${label}',
                max: 'जास्तीत जास्त ${max} ${label}',
                range: '${label} एक रक्कम ${min}-${max} दरम्यान असावे'
            },
            pattern: {
                mismatch: '${label} ${pattern} नमुना जुळत नाही'
            }
        }
    },
    Image: {
        preview: 'पूर्वावलोकन'
    }
};

export default localeValues