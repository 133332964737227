import antdInHi from 'antd/es/locale/hi_IN';
import teMsg from "../locales/in_TE.json";

const TeLang = {
  antd: antdInHi,
  locale: 'in-TE',
  messages: {
    ...teMsg
  },
};
export default TeLang;
