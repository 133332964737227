import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper"
import { ROLE_FOR } from "constants/App/RoleConstant";
import MasterService from "services/MasterServices/index";
import PlanService from "services/Plans/index";

const commonOrderBy = {
    orderBy: "created_at",
    sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`
}

class PlanStore {
    store;
    @observable tableLoading = false

    @observable hospitalPlansTableData = []
    @observable hospitalPlansTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        ds_for: ROLE_FOR.HOSPITAL,
    };
    @observable isServiceModal = false;
    @observable serviceRecord = {};
    @observable isBtnLoading = false;
    @observable ds_price_base = false;
    @observable ds_service = false;
    @observable masterRoleParentTableData = []
    @observable services = [];
    @observable currentStep = 1;
    @observable planRecord = {}
    @observable serviceLevelLists = [];
    @observable disabledAddServiceControl = false;

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    nextStep = (value) => {
        this.currentStep = value;
    }

    saveValues = (values) => {
        this.planRecord = update(this.planRecord, {
            $set: {...this.planRecord, ...values}
        })
    }

    setValue = (key, value) => {
        this[key] = value;
    }

    handleChangeAccessControlData = (index, key, value) => {
        this.serviceLevelLists = update(this.serviceLevelLists, {
          [index]: {
            [key]: { $set: value },
          },
        });
    };

    @action handleAddAccessControl = () => {
        console.log("In Handle Add", this.serviceLevelLists);
        this.serviceLevelLists = update(this.serviceLevelLists, {
            $push: [
                {
                    ds_id: undefined,
                    ds_name: undefined,
                    plan_service_price_base: false,
                    plan_service_price: undefined,
                    plan_service_per_unit_price: undefined,
                    plan_service_unit_free: undefined,
                    isEdit: true,
                    id: Date.now() + Math.random()
                },
            ],
        });
        this.disabledAddServiceControl = true;
    };

    handleRemoveAccessControls = flow( function* (index) {
        let levelObject = this.serviceLevelLists[index];        
        if (levelObject && levelObject.plan_service_id) {
            this.isBtnLoading = true;
            const dataObject = {
                ...this.store.commonStore.getDefaultParameter(),
                plan_service_id: levelObject.plan_service_id,
                so_id: 1
            };
            const res = yield PlanService.deletePlanService(dataObject);
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(res)
            this.serviceLevelLists = update(this.serviceLevelLists, {
                $splice: [[index, 1]],
            });
            
        } else {
          this.serviceLevelLists = update(this.serviceLevelLists, {
            $splice: [[index, 1]],
          });
        }
        this.getPlansList(this.masterRoleTablePagination)
        this.disabledAddServiceControl = false;
    });

    @action getMasterParentServiceLists = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all,
                ds_for: 2
            }

            console.log("this.store.commonStore.product_name ============", this.store.commonStore.product_name);
            const { data } = yield MasterService.servicesList(this.store.commonStore.product_name, dataObjectForServer)
            let finalRecords = data.rows && data.rows.length > 0 && data.rows.filter((item) => item.ds_parent_id === null)
            console.log("finalRecords ==========", finalRecords);
            this.masterRoleParentTableData = update(this.masterRoleParentTableData, {
                $set: finalRecords
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    getPlansList = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all
            }
            const { data } = yield PlanService.plansList(this.store.commonStore.product_name, dataObjectForServer)

            console.log("Plans Records ===================", data);

            this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, {
                total: {
                    $set: data.total
                }
            })
            this.hospitalPlansTableData = update(this.hospitalPlansTableData, {
                $set: data.rows
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleRemovePlan = flow(function *(rowRecord){
        try {
            this.tableLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                plan_id: rowRecord.plan_id
            }
            const { data } = yield PlanService.deletePlan(this.store.commonStore.product_name, dataObjectForServer)
            this.store.commonStore.responseMessageHandler(data)
            this.tableLoading = false;
            this.getPlansList(this.hospitalPlansTablePagination);

            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleAddEditPlan = flow(function *(values, form) {
        try {
            console.log("Values =============", values);
            this.isBtnLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                plan_name: this.planRecord.plan_name,
                plan_active: this.planRecord.plan_active,
                consider_price: this.planRecord.consider_price ? this.planRecord.consider_price : false,
                plan_price: this.planRecord.plan_price ? Number(this.planRecord.plan_price) : Number(0),
                allow_free_days: this.planRecord.allow_free_days ? this.planRecord.allow_free_days : false,
                free_days: this.planRecord.free_days ? Number(this.planRecord.free_days) : Number(0),
                services: this.serviceLevelLists
            }
            if (this.serviceRecord) {
                dataObjectForServer.plan_id = this.serviceRecord.plan_id
            }
            const res  = yield PlanService.addUpdatePlan(this.store.commonStore.product_name, dataObjectForServer)
            this.isBtnLoading = false
            this.isServiceModal = false;
            form && form.resetFields();
            this.serviceRecord = {};
            this.serviceLevelLists = 
            this.masterRoleParentTableData = [];
            this.currentStep = 1;
            this.store.commonStore.responseMessageHandler(res)
            this.getPlansList(this.hospitalPlansTablePagination);
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleVisibleAddEditPlanDrawer = (visible, record = {}, form) => {
        this.isServiceModal = visible;
        if(visible === false) {
            form && form.resetFields();
            this.serviceRecord = record;
            this.currentStep = 1;
            this.serviceLevelLists = [];
        } else {
            this.getMasterParentServiceLists(this.hospitalPlansTablePagination ,true, false)
            this.serviceRecord = record;
            this.serviceLevelLists = record.services || [];
        }
    }

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, objToUpdate)
                this.getPlansList(this.hospitalPlansTablePagination)
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, sortToUpdate)
                this.getPlansList(this.hospitalPlansTablePagination)
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, updateObject)
        if (!value) {
            this.onSearch(value)
        }
    }

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, updateObject)
        this.getPlansList(this.activeTab, this.hospitalPlansTablePagination)
    }

}

export default PlanStore