import antdInHi from 'antd/es/locale/hi_IN';
import punMsg from "../locales/in_PUN.json";

const PunLang = {
  antd: antdInHi,
  locale: 'in-PUN',
  messages: {
    ...punMsg
  },
};
export default PunLang;
