import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import Icon from "../../util-components/Icon";
import { observer } from "mobx-react"
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = observer((props) => {
  const {
    routeInfo,
    hideGroupTitle,
    commonStore: {
      product_id
    },
    themeStore: {
      onMobileNavToggle,
      sideNavTheme,
      sideMenu,
      MenuClick,
      getSelectedMenu,
      headerNavColor,
    },
    intl: {
      formatMessage
    }
  } = props;
  const mode = utils.getColorContrast(headerNavColor)
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }
  const MainMenu = sideMenu

  console.log("product_id =============", product_id);
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      // defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      onClick={(item) => MenuClick(item)}
      selectedKeys={getSelectedMenu(props, MainMenu)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {MainMenu.map((menu) => {
        // const showIds = [...access_controls].filter((e) => menu.access_control.indexOf(e) !== -1);
        let toShow = menu.product_id.includes(product_id) //!!showIds.length;
        // if (menu.submenu.length) {
        //   let ids = menu.access_control.filter((id) => access_controls.includes(id));
        //   toShow = !!ids.length;
        // }
        return menu.submenu.length > 0 && toShow ? (
          <Menu.ItemGroup
            key={menu.key}
            title={formatMessage({ id: menu.title })}
          >
            {menu.submenu.map((subMenuFirst) => {
              // const showIdsSub = [...access_controls].filter((e) => subMenuFirst.access_control.indexOf(e) !== -1);
              let toShowSub = true //!!showIdsSub.length;
              
              // if (subMenuFirst.submenu.length) {
              //   let ids = subMenuFirst.access_control.filter((id) => access_controls.includes(id));
              //   toShowSub = !!ids.length;
              // }
              return subMenuFirst.submenu.length > 0 && toShowSub ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={formatMessage({ id: subMenuFirst.title })}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => {
                    // const showIdsSecond = [...access_controls].filter((e) => subMenuSecond.access_control.indexOf(e) !== -1);
                    let toShowSubSecond = true //!!showIdsSecond.length;
                    return toShowSubSecond && (
                      <Menu.Item key={subMenuSecond.key} className={`${mode === 'light' ? 'light' : ''} menu-item-${headerNavColor.replace('#', "")}`}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond.icon} />
                        ) : null}
                        <span>
                          {formatMessage({ id: subMenuSecond.title })}
                        </span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item>
                    )
                  })}
                </SubMenu>
              ) : toShowSub && (
                <Menu.Item key={subMenuFirst.key} className={`${mode === 'light' ? 'light' : ''} menu-item-${headerNavColor.replace('#', "")}`}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{formatMessage({ id: subMenuFirst.title })}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            }
            )}
          </Menu.ItemGroup>
        ) : toShow && (
          <Menu.Item key={menu.key} className={`${mode === 'light' ? 'light' : ''} menu-item-${headerNavColor.replace('#', "")}`}>
            {menu.icon ? <Icon type={menu.icon} /> : null}
            <span>{formatMessage({ id: menu.title })}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      }
      )}
    </Menu>
  );
});

const TopNavContent = observer((props) => {
  const {
    themeStore: {
      headerNavColor,
      sideMenu,
    },
    intl: {
      formatMessage
    }
  } = props;
  const mode = utils.getColorContrast(headerNavColor)
  const MainMenu = sideMenu
  return (
    <Menu mode="horizontal" style={{ backgroundColor: headerNavColor }}>
      {MainMenu.map((menu) =>
        menu?.submenu.length > 0 ? (
          <SubMenu
            key={menu?.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu?.icon ? <Icon type={menu.icon} /> : null}
                <span>{menu?.title && formatMessage({ id: menu?.title })}</span>
              </span>
            }
          >
            {menu?.submenu.map((subMenuFirst) =>
              subMenuFirst?.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst?.key}
                  icon={
                    subMenuFirst?.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null
                  }
                  title={subMenuFirst?.title && formatMessage({ id: subMenuFirst?.title })}
                >
                  {subMenuFirst?.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key} className={`${mode === 'light' ? 'light' : ''} menu-item-${headerNavColor.replace('#', "")}`}>
                      <span>
                        {formatMessage({ id: subMenuSecond.title })}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst?.key} className={`${mode === 'light' ? 'light' : ''} menu-item-${headerNavColor.replace('#', "")}`}>
                  {subMenuFirst?.icon ? (
                    <Icon type={subMenuFirst.icon} />
                  ) : null}
                  <span>{subMenuFirst?.title && formatMessage({ id: subMenuFirst?.title })}</span>
                  <Link to={subMenuFirst?.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu?.key} className={`${mode === 'light' ? 'light' : ''} menu-item-${headerNavColor.replace('#', "")}`}>
            {menu?.icon ? <Icon type={menu.icon} /> : null}
            <span>{menu?.title && formatMessage({ id: menu?.title })}</span>
            {menu?.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
});

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};
export default observer(MenuContent)