import AppConstantClass from "configs/Constants";
import { observable, action, computed, makeAutoObservable, flow } from "mobx";
import AuthService from "services/AuthService";
import update from "immutability-helper"
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { message} from "antd"
class CommonStore {
    store;
    @observable reload = false;
    @observable userLoggedIn = false;

    @observable user = {}

    @observable so_id = AppConstantClass.getAdminUserId();
    @observable token = AppConstantClass.getAdminAuthToken();
    @observable product_id = ''
    @observable socket_rooms = []
    @observable products = []
    @observable product_name = 'master'
    constructor(store) {
        this.store = store
        makeAutoObservable(this, {}, { autoBind: true })
        this.reset()
    }

    resetAllStore = () => {
        Object.keys(this.store).map(store => {
            if (store && store.reset && typeof store.reset === "function") {
                store.reset()
            }
            return store
        })
    }

    @action reset = () => {
        this.reload = false;
        this.userLoggedIn = false;
        this.token = AppConstantClass.getAdminAuthToken();

        if (this.token) {
            this.so_id = AppConstantClass.getAdminUserId() ? Number(AppConstantClass.getAdminUserId()) : null;
            if (this.so_id) {
                const product = AppConstantClass.getProductInfo();
                if(product) {
                    this.product_id = product.product_id ? Number(product.product_id): null
                    this.product_name = product.product_route || product.product_name
                }
                this.initializeApp({ so_id: this.so_id, product_id: this.product_id }, null, true)
            }
        }
    }

    @action setProductName = (product_id, product_name) => {
        this.product_id = product_id;
        this.product_name = product_name.toLowerCase();
        AppConstantClass.setProductId({
            product_id, product_name
        })
    }

    @action setUserData = async ({
        data: {
            user,
            products = [],
            socket_rooms = []
        },
    }) => {
        try {
            this.user = update(this.user, {
                $set: user[0]
            });
            this.so_id = this.user.so_id;
            this.socket_rooms = update(this.socket_rooms, {
                $set: socket_rooms
            })
            this.products = products;
            
            AppConstantClass.setAdminUserId(this.so_id);
            this.access_controls = update(this.access_controls, {
              $set: [.../* access_control */[], 1910]
            })
            // this.store.themeStore.onHeaderNavColorChange(theme.color);
            // this.onLocaleChange(theme.locale || 'en')
    
            // this.getAccessControlsList()
            //this.store.themeStore.setSelectedMenu()
            this.store.themeStore.setAllowedRoutes()
        } catch(error) {
            console.log("error ==================", error);
        }
        
        return true
    }

    initializeApp = flow(async function* (userObject, cb, reload = false) {
        this.reload = reload
        try {
            const respone = yield AuthService.initApp(userObject);
            if (this.store.socketStore.socket) {
              this.store.socketStore.disconnectSocket();
            }
            this.socket_rooms = update(this.socket_rooms, {
                $set: respone.data.socket_rooms,
            });
            this.store.socketStore.connectSocket();
            await this.setUserData(respone)
            cb && cb();
            this.reload = false;
        } catch (error) {
            console.log("error", error)
        }
    })

    handleUserLoggedIn = (loggedIn, { so_id, token }) => {
        this.userLoggedIn = loggedIn;
        this.so_id = so_id;
        this.token = token
        AppConstantClass.setAdminUserId(so_id)
        AppConstantClass.setAdminAuthToken(token)
    }

    @action checkPermissionToRedirect = () => {
        console.log("checking to redirect")
        this.store.router.history.push(`${APP_PREFIX_PATH}`);
    };

    @computed getDefaultParameter = () => ({
        so_id: this.so_id
    })

    clearAllCookies = () => {
        this.reload = false;
        this.userLoggedIn = false;
        this.token = null;
        this.user = {};
        this.so_id = null;
        this.socket_rooms = [];
        localStorage.clear();
        document.cookie = "rememberMe=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        this.store.socketStore.disconnectSocket();
      };

    checkStatusToMsgType = (statusCode) => {
        if (statusCode === 401) {
            this.clearAllCookies();
            this.store.router.history.push("/user/login");
            return "error";
        }
        switch (statusCode) {
            case 201: // CREATED: 201
            case 200: // OK: 200
                return "success";
            case 401:
            case 400: // BAD_REQUEST: 400
            case 404:
                return "error";
            case 206: // PARTIAL_CONTENT: 206
            case 409: // CONFLICT: 409
                return "warning";
            default:
                return "success";
        }
    };

    @action responseMessageHandler = (res = {}) => {
        if (res && res.status) {
            if (res.message) {
                if (typeof res.message === "string") {
                    message[this.checkStatusToMsgType(res.status)](res.message);
                } else if (typeof res.message === "object") {
                    message[this.checkStatusToMsgType(res.status)](res.message.en);
                }
            } else {
                !res.data && console.error(res);
            }
        } else {
            !res.data && console.error(res);
        }
    };
}

export default CommonStore