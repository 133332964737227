import RolesStore from "views/app-views/Roles/RolesStore";
import UsersStore from "views/app-views/Users/UsersStore";
import ServiceStore from "views/app-views/Services/ServicesStore";
import PlansStore from "views/app-views/Plans/PlansStore";
import AccessControlStore from "views/app-views/AccessControl/AccessControlStore";
import AuthStore from "views/auth-views/AuthStore";
import ProductStore from "views/app-views/ProductManagement/ProductStore";
import CareerStore from "views/app-views/CareerCategoryManagement/CareerStore";
import DairyProcurementStore from "views/app-views/DairyProcurement/DairyProcurementStore";
import EnergyStore from "views/app-views/Energy/EnergyStore";
import CommonStore from "./CommonStore";
import RouterStore from "./RouteStore";
import SocketStore from "./Sockets";
import ThemeStore from "./Theme/index";
import CareerListStore from "views/app-views/CareerListManagement/CareerListStore";
import BlogStore from "views/app-views/BlogManagement/BlogStore";

class Stores {
    constructor(){
        this.router = new RouterStore(this)
        this.loadStores()
    }
    loadStores = () => {
        this.themeStore = new ThemeStore(this)
        this.commonStore = new CommonStore(this)
        this.socketStore = new SocketStore(this)
        this.authStore = new AuthStore(this)
        this.dairyProcurementStore = new DairyProcurementStore(this)
        this.rolesStore = new RolesStore(this)
        this.userStore= new UsersStore(this)
        this.serviceStore = new ServiceStore(this)
        this.planStore = new PlansStore(this)
        this.accessStore = new AccessControlStore(this)
        this.productStore = new ProductStore(this)
        this.energyStore = new EnergyStore(this);
        this.careerStore = new CareerStore(this);
        this.careerListStore = new CareerListStore(this);
        this.blogStore = new BlogStore(this);
    }
}
export default Stores