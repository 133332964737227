import React, { Component } from "react";
import { Popconfirm, Tooltip, Button } from "antd";
import { EditOutlined, DownloadOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons"
class ActionButtons extends Component {
  render() {
    const { props } = this;
    const {
      showConfirmModal = true,
      popconfirmTitle = "This record will be deleted",
      editTitle,
      deleteTitle,
      showTitle,
      downloadTitle,
      extraRight = [],
      extraLeft = [],
    } = props;
    return (
      <div
        className="add-edit-actions"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Button.Group>
          {extraLeft.map((btn) => {
            return btn;
          })}
          {props.onDownload && (
            <Tooltip
              trigger={!props.disabled ? "hover" : ""}
              title={` ${downloadTitle ? downloadTitle : "Download"}`}
              placement="bottom"
            >
              <Button
                disabled={props.disabled}
                icon={<DownloadOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onDownload();
                }}
                size="small"
              />
            </Tooltip>
          )}

          {props.onEdit && (
            <Tooltip
              trigger={!props.disabled ? "hover" : ""}
              title={` ${editTitle ? editTitle : "Edit"}`}
              placement="bottom"
            >
              <Button
                disabled={props.disabled}
                icon={<EditOutlined />}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  props.onEdit();
                }}
              />
            </Tooltip>
          )}
          {props.onShow && (
            <Tooltip title={`${showTitle ? showTitle : "View"}`} placement="bottom">
              <Button
                icon={<EyeOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onShow();
                }}
                size="small"
              // className="action-icon action-icon-eye"
              />
            </Tooltip>
          )}
          {props.onRemove &&
            (showConfirmModal ? (
              <Tooltip
                trigger={!props.disabled ? "hover" : ""}
                title={`${deleteTitle ? deleteTitle : "Delete"}`}
                placement="bottom"
              >
                <Popconfirm
                  disabled={props.disabled}
                  title={popconfirmTitle}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    props.onRemove();
                  }}
                  okText={"Yes"}
                  cancelText={"No"}
                  placement="topRight"
                >
                  <Button
                    disabled={props.disabled}
                    icon={<DeleteOutlined />}
                    type="danger"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    size="small"
                  />
                </Popconfirm>
              </Tooltip>
            ) : (
              <Button
                disabled={props.disabled}
                icon={<DeleteOutlined />}
                type="danger"
                onClick={(event) => {
                  event.stopPropagation();
                  props.onRemove();
                }}
                size="small"
              />
            ))}
          {extraRight.map((btn) => {
            return btn;
          })}
        </Button.Group>
      </div>
    );
  }
}

export default ActionButtons;
