import React from 'react'
import IntlMessage from '../../util-components/IntlMessage';

const PageHeader = ({ title }) => {
	return (
		<div className="app-page-header">
			<h3 className="mb-0 mr-3 font-weight-semibold">
				<IntlMessage id={title ? title : 'home'} />
			</h3>
		</div>
	)
}

export default PageHeader