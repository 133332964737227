import fetch from 'auth/FetchInterceptor'

const CareerListService = {}
const careerListRoute = '/career-list/'

CareerListService.getCareerList = function (params) {
    return fetch({
        url: careerListRoute + 'list',
        method: 'post',
        data: params,
    })
}

CareerListService.addUpdateCareerList = function (params) {
    return fetch({
        url: careerListRoute + 'create',
        method: 'post',
        data: params,
    })
}

CareerListService.deleteCareerList = function (params) {
    return fetch({
        url: careerListRoute + 'delete',
        method: 'delete',
        data: params,
    })
}

export default CareerListService