import socketIO from "socket.io-client";
import AppConstantClass from "configs/Constants";
import { observable, action } from "mobx";
import { env } from './../configs/EnvironmentConfig'
class SocketStore {
    store;
    @observable socket = null;
    @observable showReloadModal = false;
    @observable isOnline = true;
    @observable isDisconnect = true;
    constructor(store) {
        this.store = store
    }

    handleSocketOn = (eventName, callBack) => {
        this.socket.on(eventName, ({ data }) => {
            // this.allStore.globals.handleConsole(eventName, RestfulProvider.decryptedResponse(data));
            // let getData = RestfulProvider.isEncrypt ? RestfulProvider.decryptedResponse(data) : data;
            let getData = data;
            console.log('[Sockt] incoming : ', eventName, data)
            callBack(getData);
        });
    };
    handleSocketEmit = (eventName, data) => {
        console.log('[Sockt] outgoing : ', eventName, data)
        this.socket.emit(eventName, data);
    };
    @action disconnectSocket = () => {
        console.log('[Sockt] disconnected')
        this.socket && this.socket.close(env.API_SOCKET_URL);
    };

    @action connectSocket = () => {
        console.log("[Socket] connecting...")
        let isConnect = !!(this.socket && this.socket.connected);
        if (AppConstantClass.getAdminAuthToken() && this.isOnline && !isConnect) {
            this.socket = socketIO.connect(env.API_SOCKET_URL, {
                transports: ["websocket"],
                upgrade: false,
            });
            console.log("[Socket] connected!")
            this.onlineSocket();
            this.isDisconnect = false;
        }
    };

    @action onlineSocket = () => {
        let { so_id } = this.store.commonStore;
        console.log("[Socket] online")
        this.handleSocketEmit("online", {
            tag: "master",
            type: "web",
            so_id: so_id,
        });
        this.handleSocketOn("online", data => {
            const {
                commonStore: {
                    socket_rooms
                }
            } = this.store
            data.success && this.subscribeAndConnect(socket_rooms);
        })
        // this.handleSocketOn("online_new", (data) => {
        //     let {
        //         employee: { chatrooms },
        //     } = this.allStore.globals;
        //     this.allStore.globals.handleConsole(data);
        //     data.success && this.subscribeSocket(chatrooms);
        // });
    }

    @action subscribeAndConnect = (socketRooms = []) => {
        console.log("socketRooms", socketRooms)
        this.handleSocketEmit("subscribe", { rooms: socketRooms });

        window.addEventListener("online", () => {
            console.log("online window");
            this.isOnline = true;
        });

        window.addEventListener("offline", () => {
            console.log("offline window");
            this.isOnline = false;
        });

        this.socket.on("disconnect", () => {
            console.log("In disconnected----");
            this.isDisconnect = true;
            this.disconnectSocket();
            if (AppConstantClass.getAdminAuthToken()) {
                this.connectSocket();
            }
        });

        this.handleSocketOn("broadcast-role", (data) => {
            this.store.rolesStore.handleSockets(data)
        })
    }
}

export default SocketStore