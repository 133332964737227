import { observable, makeAutoObservable, flow, action } from "mobx";
import Utils from "utils/index";
import update from "immutability-helper"
import { ROLE_FOR } from "constants/App/RoleConstant";
import ProductService from "services/Products/index";

const commonOrderBy = {
    orderBy: "created_at",
    sortBy: "DESC",
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`
}

class ProductStore {
    store;
    @observable tableLoading = false

    @observable hospitalPlansTableData = []
    @observable hospitalPlansTablePagination = {
        ...Utils.DEFAULT_PAGINATION,
        ...commonOrderBy,
        ds_for: ROLE_FOR.HOSPITAL,
    };
    @observable isServiceModal = false;
    @observable serviceRecord = {};
    @observable isBtnLoading = false;
    @observable ds_price_base = false;
    @observable ds_service = false;
    @observable masterRoleParentTableData = []
    @observable services = [];
    @observable allProductLists = [];

    constructor(stores) {
        this.store = stores
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setValue = (key, value) => {
        this[key] = value;
    }

    getAllProductLists = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all
            }
            const { data } = yield ProductService.productsList(dataObjectForServer)
            
            this.allProductLists = update(this.allProductLists, {
                $set: data.rows
            })
        } catch (error) {
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    getProductList = flow(function* (pagination = Utils.DEFAULT_PAGINATION, all = false, loader = true) {
        try {
            this.tableLoading = loader;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                ...Utils.convertPaginationparams(pagination),
                all:all
            }
            const { data } = yield ProductService.productsList(dataObjectForServer)

            this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, {
                total: {
                    $set: data.total
                }
            })
            this.hospitalPlansTableData = update(this.hospitalPlansTableData, {
                $set: data.rows
            })
            this.tableLoading = false
        } catch (error) {
            this.tableLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleRemoveProduct = flow(function *(rowRecord){
        try {
            this.tableLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                product_id: rowRecord.product_id
            }
            const { data } = yield ProductService.deleteProduct(dataObjectForServer)
            this.store.commonStore.responseMessageHandler(data)
            this.tableLoading = false;
            this.getProductList(this.hospitalPlansTablePagination);

            
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleAddEditProduct = flow(function *(values, form) {
        try {
            this.isBtnLoading = true;
            const dataObjectForServer = {
                ...this.store.commonStore.getDefaultParameter(),
                product_name: values.product_name,
                product_route: values.product_route,
                product_active: values.product_status
            }
            if (this.serviceRecord) {
                dataObjectForServer.product_id = this.serviceRecord.product_id
            }
            const res  = yield ProductService.addUpdateProduct(dataObjectForServer)
            this.isBtnLoading = false
            this.isServiceModal = false;
            form && form.resetFields();
            this.serviceRecord = {}
            this.store.commonStore.responseMessageHandler(res)
            this.getProductList(this.hospitalPlansTablePagination);
        } catch (error) {
            this.isBtnLoading = false;
            this.store.commonStore.responseMessageHandler(error)
        }
    })

    @action handleVisibleAddEditProductDrawer = (visible, record = {}, form) => {
        console.log("record ======================", record);
        this.isServiceModal = visible;
        if(!!Object.keys(record).length) {
            this.serviceRecord =  update(this.serviceRecord,{
                $set: record
            });
        } else {
            this.serviceRecord = update(this.serviceRecord,{
                $set: {}
            });
        }
        if(visible === false) {
            form && form.resetFields();
        }
    }

    @action onTrackTableChange = (pagination, filters, sorter, { action }) => {
        switch (action) {
            case "paginate":
                const objToUpdate = {
                    current: {
                        $set: pagination.current
                    },
                    pageSize: {
                        $set: pagination.pageSize
                    }
                }
                this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, objToUpdate)
                this.getProductList(this.hospitalPlansTablePagination)
                break;
            case "sort":
                const sortToUpdate = {
                    orderBy: {
                        $set: sorter.field || "created_at"
                    },
                    sortBy: {
                        $set: sorter.order === "ascend" ? "ASC" : "DESC"
                    }
                }
                this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, sortToUpdate)
                this.getProductList(this.hospitalPlansTablePagination)
                break;
            default:
                break;
        }
    }

    @action onSearchChange = ({ target: { value } }) => {
        const updateObject = {
            search: {
                $set: value
            }
        }
        this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, updateObject)
        if (!value) {
            this.onSearch(value)
        }
    }

    @action onSearch = (value) => {
        const updateObject = {
            current: {
                $set: 1
            },
            search: {
                $set: value
            }
        }
        this.hospitalPlansTablePagination = update(this.hospitalPlansTablePagination, updateObject)
        this.getProductList(this.hospitalPlansTablePagination)
    }

}

export default ProductStore