import fetch from 'auth/FetchInterceptor'

const BlogService = {}
const blogRoute = '/blog/'

BlogService.getBlogManagementList = function (params) {
    return fetch({
        url: blogRoute + 'list',
        method: 'post',
        data: params,
    })
}

BlogService.addUpdateBlog = function (params) {
    return fetch({
        url: blogRoute + 'create',
        method: 'post',
        data: params,
    })
}

BlogService.deleteBlog = function (params) {
    return fetch({
        url: blogRoute + 'delete',
        method: 'delete',
        data: params,
    })
}

BlogService.deleteBlogImage = function (params) {
    return fetch({
        url: blogRoute + 'delete-blog-image',
        method: 'delete',
        data: params,
    })
}
export default BlogService