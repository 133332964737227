

import fetch from 'auth/FetchInterceptor'

const AccessService = {}
const accessRoute = '/access_controls/'

AccessService.accessControlLists = function (pathname, params) {
    return fetch({
        url: accessRoute + pathname + '/list',
        method: 'post',
        data: params,
    })
}

AccessService.addUpdateAccessControl = function (pathname, params) {
    return fetch({
        url: accessRoute + pathname + '/create',
        method: 'post',
        data: params,
    })
}

AccessService.deleteAccessControl = function (pathname, params) {
    return fetch({
        url: accessRoute + pathname + '/delete_control',
        method: 'post',
        data: params,
    })
}

AccessService.deleteAccessControlGroup = function (pathname, params) {
    return fetch({
        url: accessRoute + pathname + '/delete',
        method: 'post',
        data: params,
    })
}



export default AccessService