import enLang from './entries/en_US';
import zhLang from './entries/zh_CN';
import frLang from './entries/fr_FR';
import jaLang from './entries/ja_JP';
import guLang from './entries/in_GU';
import hiLang from './entries/in_HI';
import maLang from './entries/in_MA';
import taLang from './entries/in_TA';
import teLang from './entries/in_TE';
import punLang from './entries/in_PUN';

const AppLocale = {
    en: enLang,
    zh: zhLang,
    fr: frLang,
    ja: jaLang,
    gu: guLang,
    hi: hiLang,
    ma: maLang,
    ta: taLang,
    te: teLang,
    pun: punLang
};

export default AppLocale;