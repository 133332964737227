import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import {
  SettingOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from '../../util-components/Icon';
import { inject, observer } from "mobx-react";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Link } from "react-router-dom";

const menuItem = [
  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: APP_PREFIX_PATH + '/account-setting'
  },
]

const NavProfile = ({ authStore, authStore: { signOut }, user }) => {
  const profileImg = user.image;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {profileImg && <Avatar size={45} src={profileImg} />}
          {!profileImg && <Avatar size={45}  >{user.so_fname[0]}{user.so_lname[0]}</Avatar>}
          <div className="pl-3">
            <h4 className="mb-0">{user.so_fname} {user.so_lname}</h4>
            <span className="text-muted">{user.role_name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item 
            key={menuItem.legth + 1} 
            onClick={e => signOut(true)}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          {profileImg && <Avatar src={profileImg} />}
          {!profileImg && <Avatar   >{user.so_fname[0]}{user.so_lname[0]}</Avatar>}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default inject("authStore")(observer(NavProfile))