import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT, SIDE_MENU } from "constants/ThemeConstant";
import { observable, action, makeAutoObservable, computed } from "mobx";
import sideMenuConfig from "configs/SideMenuConfig"
import update from "immutability-helper"
class ThemeStore {
    store;
    @observable loadingButton = false
    @observable navCollapsed = false;
    @observable sideNavTheme = SIDE_NAV_LIGHT;
    @observable locale = 'en';
    @observable navType = NAV_TYPE_SIDE;
    @observable topNavColor = '#3e82f7';
    @observable headerNavColor = '#ffffff';
    @observable mobileNav = false;
    @observable isMobile = false;
    @observable buttonColor = 'rgb(63, 130, 247)';
    @observable someMessage = ''
    @observable buttonTextColor = "rgb(255 255 255)";
    @observable sideMenu = sideMenuConfig
    @observable selectedRoute = "";
    constructor(store) {
        this.store = store;
        makeAutoObservable(this, {}, { autoBind: true })
        this.reset()
    }

    reset = () => {
        // this.navCollapsed = false;
        this.sideNavTheme = SIDE_NAV_LIGHT;
        this.locale = 'en';
        this.navType = NAV_TYPE_SIDE;
        this.topNavColor = '#3e82f7';
        this.headerNavColor = '#ffffff';
        this.mobileNav = false;
    }

    @action MenuClick = (item) => {
        localStorage.setItem(SIDE_MENU, [item.keyPath[item.keyPath.length - 1]]);
        this.selectedMenu = [item.keyPath[item.keyPath.length - 1]];
    };

    @action toggleCollapsedNav = (navCollapsed) => {
        this.navCollapsed = navCollapsed
    }

    @action onMobileNavToggle = (mobileNav) => {
        this.mobileNav = mobileNav
    }

    @action onNavTypeChange = (navType) => {
        this.navType = navType
    }

    @action onNavStyleChange = (sideNavTheme) => {
        this.sideNavTheme = sideNavTheme
    }

    @action onTopNavColorChange = (topNavColor) => {
        this.topNavColor = topNavColor
    }

    @action onHeaderNavColorChange = (headerNavColor) => {
        this.headerNavColor = headerNavColor
    }

    @action setMessage = (message) => {
        this.someMessage = message
    }

    @action setIsMobile = (mode) => {
        this.isMobile = mode
    }

    @action setAllowedRoutes = () => {
        let allowedRoutes = [];
        this.sideMenu && this.sideMenu.length && this.sideMenu.map((item) => {            
            allowedRoutes.push(item.path)
            return item
        })
        this.selectedRoute = update(this.selectedRoute, {
            $set: allowedRoutes[0]
        });
    }

    @computed getSelectedMenu = (props, contents) => {
        let currentRoute = props.location.pathname.split("/")[2];
        let currentActiveKey = [];
        const recursiveGetKey = (dataSource) => {
            dataSource.forEach((data) => {
                let route = data.path.split("/")[2];
                if (route === `${currentRoute}`) currentActiveKey = [data.key];
                if (data.submenu) recursiveGetKey(data.submenu);
            });
            return currentActiveKey;
        };
        return recursiveGetKey(contents);
    };
}

export default ThemeStore