import fetch from 'auth/FetchInterceptor'

const DairyClientServices = {}
const dairyClientRoute = '/eproc/'

DairyClientServices.dairyClientList = function (params) {
    return fetch({
        url: dairyClientRoute + 'list',
        method: 'post',
        data: params,
    })
}


DairyClientServices.saveDairyClient = function (params) {
    return fetch({
        url: dairyClientRoute + 'create',
        method: 'post',
        data: params,
    })
}

DairyClientServices.deletePlanService = function (params) {
    return fetch({
        url: dairyClientRoute + 'deletePlanService',
        method: 'post',
        data: params,
    })
}



export default DairyClientServices