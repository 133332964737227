import React from "react";
import ActionButtons from "shared-components/ActionButtons/index"

const staticTableColumns = (formatMessage) => [
    {
        title: formatMessage({ id: "acc_group_name"}),
        dataIndex: "acc_group_name",
        key: "acc_group_name",
        width: 200,
        sorter: true,
        ellipsis: true,
        render: (_, elm) => elm.acc_group_name
    }
]

export const ExpandableColumns = (props) => {
    const {
        accessStore,
        intl: {
            formatMessage
        } 
    } = props;
    return [
        ...staticTableColumns(formatMessage),
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            align: "left",
            render: (_, elm) => (
                <div className="text-right">
                    <ActionButtons 
                        onEdit={() => accessStore.handleVisibleAddEditUserDrawer(true, elm)}
                        popconfirmTitle={'Are you sure you want to delete this record?'}
                        onRemove={() => accessStore.handleRemoveUser(elm)}
                    />
                </div>
            )
        }
    ]
}